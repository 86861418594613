import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import moment from "moment-timezone";
import { FcShipped } from "react-icons/fc";
import AdvancedTag from "../../popup/AdvancedTag";


function numberWithCommas(x) {
    if (x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "0.00";
    }
}

export const confirmHistoryColumn = (userinfo) => [

   
    // {
    //     Header: "Status",
    //     accessor: "status",
    //     className: "text-center align-middle",
    //     Cell: (row) => (
    //         <>
    //             {row.row.original.vCustomer === "12"  ? 
    //                 <OverlayTrigger
    //                     placement="top"
    //                     overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
    //                 >
    //                    <div className="d-flex align-items-center justify-content-center">
    //                        {row.row.original.status === 'QC REVIEW IN' || row.row.original.status === 'QC REVIEW OUT' ? <span className={`label label-lg label-outline-${row.row.original.supplierMgrAppr.supplierMgrAppr == 'N' ? "danger" : "warning"} label-pill label-inline bg-${row.row.original.supplierMgrAppr.supplierMgrAppr == 'N' ? "danger" : "warning"}-o-20 font-weight-bold text-nowrap`}>QC REVIEW</span> : null }
    //                        {row.row.original.status === 'QC_OK' ? <span className={`label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap`}>QC OK</span> : null}
    //                        {row.row.original.status === 'QC_OK_OUT' ? <span className={`label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap`}>QC OK OUT </span> :null}
    //                        {row.row.original.status === 'PENDING'||row.row.original.status === 'READY_TO_PICKUP' ? <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Confirmed</span> : null}
    //                         <FcShipped className="ml-2 font-size-h4"/>
    //                    </div>
    //                 </OverlayTrigger>
    //             : 
    //                 <>
    //                     {row.row.original.status === 'QC REVIEW IN' || row.row.original.status === 'QC REVIEW OUT' ? <span className={`label label-lg label-outline-${row.row.original.supplierMgrAppr.supplierMgrAppr == 'N' ? "danger" : "warning"} label-pill label-inline bg-${row.row.original.supplierMgrAppr.supplierMgrAppr == 'N' ? "danger" : "warning"}-o-20 font-weight-bold text-nowrap`}>QC REVIEW</span> : null }
    //                     {row.row.original.status === 'QC_OK' ? <span className={`label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap`}>QC OK</span> : null}
    //                     {row.row.original.status === 'QC_OK_OUT' ? <span className={`label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap`}>QC OK OUT </span> :null}
    //                     {row.row.original.status === 'PENDING'||row.row.original.status === 'READY_TO_PICKUP' ? <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Confirmed</span> : null}
    //                 </>
    //             }
                
    //         </>
    //     ),
    // },
    {
        Header: "Status",
        accessor: "status",
        className: "text-center align-middle",
        Cell: (row) => {
            const { orderStatus, vCustomer } = row.row.original;
            const statusConfig = {
                'QC REVIEW': {
                    className: `label-lg label-outline-warning bg-warning-o-20`,
                    displayText: 'QC REVIEW'
                },
                'QC OK': {
                    className: 'label-lg label-outline-success bg-success-o-20',
                    displayText: 'QC OK'
                },
                'QC OK OUT': {
                    className: 'label-lg label-outline-warning bg-warning-o-20',
                    displayText: 'QC OK OUT'
                },
                'Confirmed': {
                    className: 'label-lg label-outline-success bg-success-o-20',
                    displayText: 'Confirmed'
                },
                'QC Review Approved': {
                    className: 'label-lg label-outline-success bg-success-o-20',
                    displayText: 'QC Review Approved'
                },
                'QC Rejected': {
                    className: 'label-lg label-outline-danger bg-danger-o-20',
                    displayText: 'QC Rejected'
                },
                'QC Approved': {
                    className: 'label-lg label-outline-success bg-success-o-20',
                    displayText: 'QC Approved'
                },
                'Submit For QC': {
                    className: 'label-lg label-outline-primary bg-primary-o-20',
                    displayText: 'Submit For QC'
                }
                // Add other statuses as needed
            };
            const config = statusConfig[orderStatus] || "-"

            const statusElement = (
                <span className={`label label-pill label-inline font-weight-bold text-nowrap ${config.className}`}>
                    {config.displayText}
                </span>
            );

            // Special Ship Stone case
            if (vCustomer === "12") {
                return (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            {statusElement}
                            <FcShipped className="ml-2 font-size-h4" />
                        </div>
                    </OverlayTrigger>
                );
            }

            return statusElement;

        },
    },
    {
        Header: "Date & Time",
        // accessor: "createdDate",
        accessor: (d) => {
            return (
                <>
                    {  d.createdDate ? d.createdDate : "-"}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Order ID",
        accessor: "gid",
        className: "text-center align-middle",
    },
    // {
    //     Header: "Stone ID" ,
    //     accessor: "Loat_NO",
    //     className: "text-center align-middle",
        
    // },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.cShape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.cShape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-2 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`text-primary small ml-3`}>
                                {d.diamondType}-{d.parcelId}-{d.splitId}{d.cgSplitId ?<>/{d.cgSplitId}</> : ""}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.lengthMm} - {d.widthMm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor}{" "}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.image &&  d.image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={d.image}
                                width="50px"
                                onError={e => toAbsoluteUrl(
                                    e.currentTarget.src = `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "W" || d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy"  ? "NF" : "N" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.loatNO}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.certificate_link
                                }
                            >
                                {d.lab}-{d.certiNo}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor === "fancy" ? (
                                <>
                                    {d.fIntensity}{" "}{d.fOvertone}{" "}{d.fColor}{" "}
                                </>
                            ) : (
                                <>{d.cColor}{" "}</>
                            )}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },
    {
            Header: "Advanced tags",
            accessor: (d) => (
                <>
                    <AdvancedTag {...d}/>
                </>
            ),
            className: "text-center align-middle",
        },
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    ${numberWithCommas(parseFloat(d.aPrice))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT ${numberWithCommas(parseFloat(d.acRate))}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),

    },

    {
        Header: "Del.Location",
        className: "text-center align-middle",
        accessor: "firstLocation",
    },
    {
        Header: "Exchange Rate",
        className: "text-center align-middle",
        accessor: (d) => {
            return (
                <>
                   {d.curInr <= 1 ?  "$" : <span style={{fontFamily:"sans-serif"}}>₹</span>}{parseFloat(d.curInr).toFixed(2)}
                </>
            );
        },
       
    },
    {
        Header: "Local Price",
        className: "text-right  text-nowrap align-middle",
        accessor: (d) => {
            return (
                <>
                    {d.firstLocation === 'Mumbai' || d.firstLocation === 'Surat' ? 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in INR</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span> {numberWithCommas(d.aPrice * d.curInr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span>/CT: <span style={{fontFamily:"sans-serif"}}> ₹</span> {numberWithCommas(d.curInr * d.acRate)}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                            
                        </> : 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in USD</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        $ {numberWithCommas(d.aPrice * d.curInr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        $/CT: <span style={{fontFamily:"sans-serif"}}> </span> {numberWithCommas(d.curInr * d.acRate)}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                        </> 
                    }
                </>
            );
        },
       
    }
];
