import React from "react";
import { Field } from "formik";

export const Crown = () => {
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">CROWN</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>Height %</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Crown_Height_Prc"
                                        id="Min_Crown_Height_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Crown_Height_Prc"
                                        id="Max_Crown_Height_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>ANGLE %</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Crown_Angle_Prc"
                                        id="Min_Crown_Angle_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">°</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Crown_Angle_Prc"
                                        id="Max_Crown_Angle_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">°</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
