import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import moment from "moment-timezone";
import { FcShipped } from "react-icons/fc";
import AdvancedTag from "../../popup/AdvancedTag";



export const HoldCancelledColumn = (userinfo) => [

   
    // {
    //     Header: "Status",
    //     accessor: "supStatus",
    //     className: "text-center align-middle",
    //     Cell: (row) => (
    //         <>
    //             {row.row.original.vCustomer === "12"  ? 
    //                 <OverlayTrigger
    //                     placement="top"
    //                     overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
    //                 >
    //                    <span className="d-flex align-items-center justify-content-center">
    //                        {row.row.original.supStatus == '3' ? <span className="label label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Approved</span>
    //                             : row.row.original.supStatus == '2' || row.row.original.oSupplierStatus == '2'
    //                             ? <>
    //                                 <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold text-nowrap">{row.row.original.oSupplierStatus != '2' ? "Released" : "Cancelled"}</span>
    //                             </>
    //                             : row.row.original.supStatus == "1" ? "-" 
    //                             : row.row.original.cgStatus == "4" ||  row.row.original.cgStatus == "5" ?  <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold text-nowrap">Released</span> : "-"
    //                         }
    //                         <FcShipped className="ml-2 font-size-h4"/>
    //                    </span>
    //                 </OverlayTrigger>
    //             : 
    //                 <>
    //                     {row.row.original.supStatus == '3' ? <span className="label label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Approved</span>
    //                         : row.row.original.supStatus == '2' || row.row.original.oSupplierStatus == '2'
    //                         ? <>
    //                             <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold text-nowrap">{row.row.original.oSupplierStatus != '2' ? "Released" : "Cancelled"}</span>
    //                         </>
    //                         : row.row.original.supStatus == "1" ? "-" 
    //                         : row.row.original.cgStatus == "4" ||  row.row.original.cgStatus == "5" ?  <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold text-nowrap">Released</span> : "-"
    //                     }
    //                 </>
    //             }
                
    //         </>
    //     ),
    // },
    {
        Header: "Status",
        accessor: "supStatus",
        className: "text-center align-middle",
        Cell: (row) => {
            const statusConfig = {
                "Approved": {
                    className: "label-outline-success bg-success-o-20",
                    displayText: "Approved"
                },
                "Released": {
                    className: "label-outline-danger bg-danger-o-20",
                    displayText: "Released"
                },
                "Cancelled": {
                    className: "label-outline-danger bg-danger-o-20",
                    displayText: "Cancelled"
                },
                "Default": {
                    displayText: "-"
                }
            };
            const { orderStatus, vCustomer } = row.row.original;
            const config = statusConfig[orderStatus] || { displayText: "-" };

            // Special Ship Stone case
            if (vCustomer === "12") {
                return (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
                    >
                        <span className="d-flex align-items-center justify-content-center">
                            <span className={`label label-pill label-inline font-weight-bold text-nowrap ${config.className || ''}`}>
                                {config.displayText}
                            </span>
                            <FcShipped className="ml-2 font-size-h4" />
                        </span>
                    </OverlayTrigger>
                );
            }

            // Default case
            return (
                <span className={`label label-pill label-inline font-weight-bold text-nowrap ${config.className || ''}`}>
                    {config.displayText}
                </span>
            );
        },
    },
    {
        Header: "Order ID",
        accessor: "confirmGoodsId",
        className: "text-center align-middle",
    },
    // {
    //     Header: "Order Type",
    //     accessor: (d) => (
    //         <>
    //             <span className="font-weight-bolder text-primary">
    //                 {d.isHold?
    //                 <span className="label label-light-warning label-pill label-inline font-weight-bold text-nowrap">Hold Request</span>:
    //                 <span className="label label-light-success label-pill label-inline font-weight-bold text-nowrap">Buy Request</span>}
    //             </span>
    //         </>
    //     ),
    //     className: "text-center align-middle",
    // },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.cShape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.cShape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-2 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`text-primary small ml-3`}>
                                {d.diamondType}-{d.parcelId}-{d.splitId}{d.cgSplitId ?<>/{d.cgSplitId}</> : ""}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.lengthMm} - {d.widthMm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor}{" "}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.image &&  d.image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={d.image}
                                width="50px"
                                onError={e => toAbsoluteUrl(
                                    e.currentTarget.src = `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "W" || d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy"  ? "NF" : "N" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.loatNO}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.certificate_link
                                }
                            >
                                {d.lab}-{d.certiNo}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor === "fancy" ? (
                                <>
                                    {d.fIntensity}{" "}{d.fOvertone}{" "}{d.fColor}{" "}
                                </>
                            ) : (
                                <>{d.cColor}{" "}</>
                            )}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    }, 
   {
           Header: "Advanced tags",
           accessor: (d) => (
               <>
                   <AdvancedTag {...d}/>
               </>
           ),
           className: "text-center align-middle",
       },
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    ${(parseFloat(d.oRate * d.cWeight).toFixed(2))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT ${(parseFloat(d.oRate).toFixed(2))}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
       
    },
    {
        Header: "Date & Time",
        accessor: (d) => {
            return (
                
                d.createdDate ? <>
                    {d.createdDate}
                </> : "-"
            );
        },
        className: "text-center align-middle text-nowrap",
    },
   
];
