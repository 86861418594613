import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Media } from "./Preference/Media";
import { Price } from "./Preference/Price";
import { Eyeclean } from "./Tinge/Eyeclean";
import { Shade } from "./Tinge/Shade";
// import { Showonly } from "./Preference/Showonly";


export const Preference = ({language,formik}) => {
       return (
        <>
            <Accordion className="search_accordion" >
                <Card>
                    <Accordion.Toggle className="sticky-top" style={{top:"64px"}}  as={Card.Header} eventKey="1" key="1">
                        ADVANCED DETAILS
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Eyeclean/>
                           <Shade/>
                           <Price formik={formik}/>
                           <Media/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
