import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
// import { login } from "../_redux/authCrud";
import axios from "axios"
import { Alert } from 'react-bootstrap';
import { BASE_URL, NODE_API_URL, SUPPLIER_BASE_URL} from "./../../../../env_config"
import SweetAlert from 'react-bootstrap-sweetalert';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  EMail: "",
  Password: "",
};

function DeleteCustomer(props) {
  const [warnMsg,setWarnMsg] = useState('')
  const { intl } = props;
  // const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [success,setSuccess] = useState("")
  const [error, setError] = useState("");
  const LoginSchema = Yup.object().shape({
    EMail: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      Password: Yup.string()
  .required(
    intl.formatMessage({
      id: "AUTH.VALIDATION.REQUIRED_FIELD",
    })
  )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  function waitForCookie(cookieName, timeout = 5000) {
    return new Promise((resolve, reject) => {
        const startTime = Date.now();

        const interval = setInterval(() => {
            // Check if the cookie exists
            const cookies = document.cookie.split("; ").reduce((acc, current) => {
                const [name, value] = current.split("=");
                acc[name] = value;
                return acc;
            }, {});

            if (cookies[cookieName]) {
                clearInterval(interval); // Stop checking
                resolve(cookies[cookieName]); // Resolve the promise
            } else if (Date.now() - startTime > timeout) {
                clearInterval(interval); // Stop checking
                reject(new Error(`Timeout: Cookie "${cookieName}" was not set within ${timeout}ms`));
            }
        }, 100); // Check every 100ms
    });
}

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setWarnMsg(false)
      enableLoading();
        axios({
          method: "POST",
          url: `${NODE_API_URL}/SUPP/delete-mobile-customer-webiste`,
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json"
          },
          data:{
            email:values.EMail,
            password:values.Password 
          }
      }).then((res) => {
          // console.log(res.data,"resres")
          if(res && res.data){
              disableLoading()
              setSubmitting(false)
              if(res.data.success){
                setSuccess(res.data.message)
                setTimeout(() => {
                  window.location.replace("/logout")
                },3000)
              }
          }
      }).catch(error => {
          disableLoading()
          setSubmitting(false)
          if(error?.response?.data?.message){
              setError(error?.response?.data?.message)
              setTimeout(() => {
                setError("")
              },3000)
          }
      })
    },
  });
  const handlePopUp = () => {
    setWarnMsg("Are you sure you want to permanently delete your account?")
  }
  return (
    <>
    {warnMsg && (
                <SweetAlert
                    warning
                    title={"Warning!"}
                    onConfirm={formik.handleSubmit}
                    onCancel={()=>setWarnMsg(false)}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                >
                    {warnMsg}
                </SweetAlert>
            )} 
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
        {/* <!-- title and description --> */}
        <meta name="title" content="Login - Diamonds on Call Limited"/>
        <meta name="description" content="Login to diamondsoncall.com. Access more than 400,000 Natural & Lab-grown diamonds with images and videos - Buy diamonds online at a single click." />
        <title>Permanently delete your Diamonds On Call Account Limited</title>
        {/* <!-- favicon --> */}
        <link rel="icon" type="image/x-icon" href="<?= base_url() ?>assets/images/favicon.ico" />
        {/* <!-- canonical --> */}
        <link rel="canonical" href={`${BASE_URL}/login`}  />
        {/* <!-- Language Tag --> */}
        <link rel="alternate" hrefLang="en" href={`${BASE_URL}/login`}  />
        <link rel="alternate" hrefLang="x-default" href={`${BASE_URL}/login`}  />
        {/* <!-- Open Graph / Facebook--> */}
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="Diamonds on Call Limited" />
        <meta property="og:title" content="Login - Diamonds on Call Limited"/>
        <meta property="og:description" content="Login to diamondsoncall.com. Access more than 400,000 Natural & Lab-grown diamonds with images and videos - Buy diamonds online at a single click."/>
        <meta property="og:url" content={`${BASE_URL}/login`} />
        <meta property="og:image" content={`${BASE_URL}/>assets/images/banner_img.png`}/>
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:title" content="Login - Diamonds on Call Limited"/>
        <meta property="twitter:description" content="Login to diamondsoncall.com. Access more than 400,000 Natural & Lab-grown diamonds with images and videos - Buy diamonds online at a single click."/>
        <meta property="twitter:url" content={`${BASE_URL}/login`} />
        <meta property="twitter:image" content={`${BASE_URL}/>assets/images/banner_img.png`}/>
        <meta name="twitter:site" content="@diamondsoncall" />
        <div className="login-form login-signin" id="kt_login_signin_form">
          <div className="mb-5">
              <h1>Permanently delete your Supplier Account</h1>
              <p className="text-muted font-weight-bold">
                Enter your email and password
              </p>
          </div>
        {success && <Alert variant="success">
                {success}
              </Alert>}  
        {error && <Alert variant="danger">
                {error === "Please verify your email!"?<div>
                  <p>{error}</p>
                  <p><a href="/resendverification">Resend verification email Click Here</a></p>
                </div>:error}
              </Alert>}
          {/*begin::Form*/}
          <form
            onSubmit={(e) => e.preventDefault()}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            ) : (
              <div className="">
                {/* <div className="alert-text">
                  Use account <strong>admin@demo.com</strong> and password{" "}
                  <strong>demo</strong> to continue.
                </div> */}
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="EMail">Email ID</label>
                  <input
                    placeholder="Enter Email Id"
                    type="email"
                    className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses("EMail")}`}
                    name="EMail"
                    {...formik.getFieldProps("EMail")}
                  />
                  {formik.touched.EMail && formik.errors.EMail ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.EMail}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="Password">Password</label>
                  <input
                    placeholder="Enter Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses("Password")}`}
                    name="Password"
                    {...formik.getFieldProps("Password")}
                  />
                  {formik.touched.Password && formik.errors.Password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.Password}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="orm-group d-flex flex-wrap justify-content-end align-items-center">
              <button
                  type="submit"
                  className={`submit btn btn-danger btn-sm px-9 py-3 shadow-sm my-4`}
                  disabled={formik.isSubmitting} 
                  onClick={() => handlePopUp()}
                >
                  Delete Account
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(DeleteCustomer));
