import React, { useEffect, useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { BASE_URL, NODE_API_URL } from "../../../env_config";
import axios from "axios";
import 'react-datepicker/dist/react-datepicker.css';
import { toAbsoluteUrl } from "../../_helpers";
import AdvancedTag from "./AdvancedTag";
import AdvancedTagSearch from "./AdvancedTagSearch";
const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999 // You can set this to a value that ensures the menu appears on top of other elements
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999
    })
};

export const UpdateAvailibility = (props) => {

    const [initialValues, setInitialValues] = useState({})

    const Availibility = [
        { value: 'Guaranteed', label: 'Guaranteed' },
        { value: 'Hold', label: 'Hold' },
        { value: 'Memo', label: 'Memo' },
        { value: 'Not Available', label: 'Not Available' },
        { value: 'Subject to Prior sale', label: 'Subject to Prior sale' }
    ];

    const [availibilityValue, setAvailibilityValue] = useState(null)
    let Schema = Yup.object().shape({
        availibility: Yup.string("Enter Availibility").required("availibility is required"),
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };


    const formik = useFormik({

        initialValues,
        enableReinitialize: true,
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            props.setLoader(true)
            let stockID = props?.data.map(value => {
                return (
                    (value.stockID)
                )
            })
            let data = { 
                ...values ,
                stockID:stockID
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/update-availibility`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                data: data
            }).then((res) => {
                // console.log(res.data,"resres")
                if (res && res.data) {
                    props.setLoader(false)
                    formik.resetForm()
                    setAvailibilityValue(null)
                    props.onHide()
                    props.setSuccessMsg("Availibility Updated Successfully!")
                    setTimeout(() => {
                        props.setSuccessMsg(null)
                    }, 10000);
                    props.HandleRefreshPage()
                    props.setUpdateCount(!props.updatecount)
                }
            }).catch((error) => {
                        // console.log(error, "ERROR");
                        if(error?.response?.data?.message === "UnAuthorized!"){
                            localStorage.clear();
                            window.location.replace(`${BASE_URL}/logout`);
                          }
            });
        }
    });
    const ResetData = () => {
        formik.resetForm()
        setAvailibilityValue(null)
        props.onHide()
    }
    return (
        <div>

            <Modal
                show={props.show}
                onHide={() => ResetData()}
                size="lg"
            >
                <Modal.Header className="font-size-h5 font-weight-bold">
                    Update Availibility
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <form onSubmit={formik.handleSubmit}>
                        {props?.data ? <div className="col-12 border-bottom">
                            <div className="table-responsive custom-scrollbar w-100 mt-4">
                                <table className="table" >
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center text-nowrap align-middle">
                                                Diamond Detail
                                            </th>
                                            <th className="text-center text-nowrap align-middle">
                                                Advanced tags
                                            </th>
                                            <th className="text-center text-nowrap align-middle">
                                                Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data.map(value => (
                                            <tr >
                                            <td className="text-center align-middle">
                                            <div className="row">
                                                        <div className="col-2 text-center p-0">
                                                            {value.image && value.image !== "null" ? (
                                                                <img
                                                                    className="img-fluid mr-2"
                                                                    alt={value.shape}
                                                                    src={value.image}
                                                                    width="50px"
                                                                    onError={toAbsoluteUrl(
                                                                        `/media/shape/${value.shape ? value.shape.toLowerCase() : ""}-no.png`
                                                                    )}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="img-fluid mr-2"
                                                                    alt={value.shape}
                                                                    src={toAbsoluteUrl(
                                                                        `/media/shape/${value.shape ? value.shape.toLowerCase() : ""}-no.png`
                                                                    )}
                                                                    onError={e => {
                                                                        e.currentTarget.src = toAbsoluteUrl(
                                                                            `/media/shape/noimage.jpg`
                                                                        )
                                                                    }}
                                                                    width="50px"
                                                                    style={{ backgroundColor: "#f4f4f4" }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="col-10 pl-2 p-0 text-left">
                                                            <div className={`mb-1 ${value.diamondType === "W" || value.diamondType === "N" ? "natTab" : "labTab"}`}>
                                                                <span className={`${value.diamondType === "W" || value.diamondType === "N" ? value.isfancy ? "F" : "W" : value.isfancy ? "LF" : "L"}_diamond mr-2 small`}>
                                                                    {value.diamondType === "W" || value.diamondType === "N" ? value.isfancy ? "NF" : "N" : value.isfancy ? "LF" : "L"}
                                                                </span>
                                                                <span className={`text-primary small ml-3`}>
                                                                    {value.loatNo}
                                                                </span>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="text-primary mx-2 small"
                                                                    href={
                                                                        value.certificate_link
                                                                    }
                                                                >
                                                                    {value.lab}-{value.certificate}
                                                                </a>
                                                            </div>

                                                            <span className="text-dark font-weight-bold d-block">
                                                                {value.shape}{" "}
                                                                {(value.carat)?.toFixed(2)}Ct.{" "}
                                                                {value.color === "fancy" ? (
                                                                    <>
                                                                        {value.fancy_intensity}{" "}{value.fancy_overtone}{" "}{value.fancy_color}{" "}
                                                                    </>
                                                                ) : (
                                                                    <>{value.color}{" "}</>
                                                                )}
                                                                {value.clarity}{" "}{value.cut}{" "}{value.polish}{" "}{value.symmetry}{" "}
                                                                {value.fluorescence}
                                                            </span>
                                                        </div>
                                                    </div>
                                            </td>
                                            <td className="text-center align-middle">
                                                <AdvancedTagSearch {...value} />
                                            </td>
                                            <td className="text-center align-middle">
                                                <span className="font-weight-bolder text-primary">
                                                    ${parseFloat(value.supplierPrice)?.toFixed(2)}
                                                </span>{" "}
                                                <br />
                                                <span className="text-muted small">
                                                    $/CT ${parseFloat(value.supplierRate)?.toFixed(2)}
                                                </span>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> : ""}
                        <div className="col-12 border-bottom">
                            <div className="col-md-6 mt-2">
                                <div className="form-group">
                                    <div className="availability-options">
                                             <Select
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                            name="availibility"
                                            options={Availibility}
                                            className={`${getInputClasses("availibility")}`}
                                            onChange={(selected) => {
                                                formik.setFieldValue("availibility", selected ? selected.value : null);
                                            }}
                                            value={Availibility.find(value => value.value === formik.values.availibility)}
                                        />
                                    </div>
                                    {formik.errors.availibility && (
                                        <div className="text-danger text-small">
                                            {formik.errors.availibility}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12 py-5 mt-8 border-top text-right">
                                <button onClick={formik.handleSubmit} type="submit" className="btn btn-sm btn-primary">Save</button>
                                <Button className="px-5 btn-sm ml-1"
                                    variant="secondary"
                                    onClick={() => ResetData()}
                                >
                                    close
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
