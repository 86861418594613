import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
const shadeMappings = {
    "none": { label: "No.SH", tooltip: "Shade: NONE" },
    "brown": { label: "BR", tooltip: "Shade: Brown" },
    "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
    "green": { label: "GR", tooltip: "Shade: Green" },
    "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
    "grey": { label: "GREY", tooltip: "Shade: Grey" },
    "black": { label: "BLK", tooltip: "Shade: Black" },
    "pink": { label: "PINK", tooltip: "Shade: Pink" },
    "blue": { label: "BLUE", tooltip: "Shade: Blue" },
    "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
};
const renderShadeLabel = (shade) => {
    const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
    if (!mapping) return null;

    return (
        <span>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
            >
                <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
            </OverlayTrigger>
        </span>
    );
};
export default function AdvancedTagSearch(value) {
    return (
        <div className="advTag" style={{ maxWidth: "150px" }}>
            {/* shade */}
            {renderShadeLabel(value.shade)}

            {value.milky && value.milky.toLowerCase() === "no milky" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                NO MILKY
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {value.milky && value.milky.toLowerCase() === "luster low" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Luster Low
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {value.milky && value.milky.toLowerCase() === "light milky" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                LIGHT MILKY
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {value.milky && value.milky.toLowerCase() === "milky" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                MILKY
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {/* eyeclean */}
            {value.eyeclean && value.eyeclean.toLowerCase() === "yes" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                100% Eye Clean
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
            {value.eyeclean && value.eyeclean.toLowerCase() === "no" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                No Eye Clean
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {/* growthtype */}
            {value.diamondType === "L" && (value.growthType && value.growthType === "CVD") ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                CVD
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
            {value.diamondType === "L" && (value.growthType && value.growthType === "HPHT") ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                HPHT
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {(value.diamondType === "L") && (value.treatment && value.treatment.toLowerCase() === "as grown") ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                As Grown
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
            {/* natural treated */}
            {(value.diamondType === "N" || value.diamondType === "W") && (value.treatment && value.treatment.toLowerCase() !== "none") ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Treated
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
        </div>
    )
}