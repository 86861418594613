import React, { useMemo, useEffect,useState } from "react";
import { useSubheader } from "../../layout";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { searchResultColumn } from "./Columns";
import { GlobleFilter } from "./../tableFilter";
import { CheckBox } from "./../tableFilter";
import { Dropdown, Modal } from 'react-bootstrap';
import SearchExpandedDetais from "../expandedDetails/SearchExpandDetails";
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaChevronDown, FaChevronRight,FaEllipsisV} from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ActiveShipping } from "../popup/ActiveShipping";
import SearchTableSkeleton from "../SkeletonLoaders/SearchTable";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../env_config";
import { RemoveStones } from "../popup/RemoveStones";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie"
import InfiniteTableSkeleton from "../SkeletonLoaders/InfiniteTableSkeleton";
import Sorting from "../popup/Sorting";
import { UpdateAvailibility } from "../popup/UpdateAvailibility";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const SearchResult = ({userinfo}) => {
    const history = useHistory()
    const location = useLocation();
    const scrollRef = React.useRef(null);
    const isStockSearch = location.pathname.includes('stock-search');
    const [stoneData, setStoneData] = useState(location.state.searchresult);
    const [shipPopup,setShipPopup] = useState(false)
    const [updateAvailibilityPopup,setUpdateAvailibility] = useState(false)
    const [removeStonePopup,setRemoveStonePopup] = useState(false)
    const [successMsg,setSuccessMsg] = useState(null)
    const [temp,setTemp] = useState(false)
    const [shipStone,setShipStone] = useState([])
    const [stoneCount,setStoneCount] = useState(0)
    const [loader,setLoader] = useState(false)
    const [infiniteLoader,setInfiniteLoader] = useState(false)
    const [updatecount,setUpdateCount] = useState(false)
    const [sortobj,setSortObj] = useState({id:"",sortorder:""})
    const [countloader,setCountLoader] = useState(true)
    const [disablesorting,setDisableSorting] = useState(false)
    const [pagenumber,setPageNumber] = useState(location.state.page || 1)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);

    const [totalDiamonds, setTotalDiamonds] = useState("0");
    const [totalDollarCarat, setTotalDollarCarat] = useState("0");
    const [totalPrice, setTotalPrice] = useState("0");
    const [totalCarat, setTotalCarat] = useState("0");

    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <SearchExpandedDetais row={row.row.original} userinfo={userinfo} />
            </>
        ),[]
      )
    const suhbeader = useSubheader();

    suhbeader.setTitle("Search Result");
    document.title ="Search Result | Diamonds on Call - Suppliers Center";
    useEffect(() => {
        window.onbeforeunload = (event) => {
            sessionStorage.setItem("pageReloaded","1")
          };
      }, []);
    const ReloadStockPage = () => {
        setLoader(true)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search-by-stock`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            },
            data: location.state.finalData,
        })
            .then(function (res) {
                setStoneData(res.data.data)
                history.push({
                    pathname: "/search-result/stock-search",
                    state: {
                        searchresult: res.data.data,
                        oldvalues: null,
                        finalData: location.state.finalData
                    },
                    // state: decompress(res.data.ProductDetails),
                });
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
            })
            .catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                                localStorage.clear();
                                window.location.replace(`${BASE_URL}/logout`);
                            }
            })
    }
    const ReloadPage = () => {
        setLoader(true)
        const fancy = Cookies.get("fancy")?1:0
                    window.Nat = location.state.oldvalues;
                    let len = Object.keys(location.state.oldvalues).length;
                    const temp = location.state.oldvalues;
                    let data = "";
                    for (let key in temp) {
                        len--;
                        let arr = temp[key];
                        if (len) {
                            if (Array.isArray(arr)) {
                                let str = "";
                                str += "'" + arr.join("','") + "'";
                                data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                            } else {
                                data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                            }
                        } else {
                            if (Array.isArray(arr)) {
                                let str = "";
                                str += "'" + arr.join("','") + "'";
                                data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                            } else {
                                data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                            }
                        }
                    }
                    let diamond_type = "N"
                    if(userinfo.type !== "Natural"){
                        diamond_type = "L"
                    }
                const finalData = `{ "JsonData":  [${data}], "WLDiamondType": "${diamond_type}" ,"page":1,"fancy":"${fancy}" }`;
               
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            },
            data: finalData,
        })
            .then(function (res) {
                setLoader(false)
                setStoneData(res.data.data)
                setPageNumber(1)
                history.push({
                    pathname: `/search-result`,
                    state: {
                        searchresult: res.data.data,
                        oldvalues: location.state.oldvalues,
                        page: 1
                    },
                    // state: decompress(res.data.ProductDetails),
                });
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
            })
            .
            catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                                localStorage.clear();
                                window.location.replace(`${BASE_URL}/logout`);
                            }
            })
    }
    useEffect(() => {
        const getpagereload = sessionStorage.getItem("pageReloaded")
        if(getpagereload === "1"){
           if(!location.state.oldvalues){
            ReloadStockPage()
           }else{
            ReloadPage()
           }
            sessionStorage.setItem("pageReloaded","0")
        }
    },[])
    useEffect(() => {
        setStoneData(location.state.searchresult)
        if(!location.state.oldvalues){
            setStoneCount(location.state.searchresult.length)
            setDisableSorting(true)
        }
        else{
            setDisableSorting(false)
        }
    },[location.state])
    useEffect(() => {
        if(location.state.oldvalues){
        const fancy = Cookies.get("fancy")?1:0
        setCountLoader(true)
        let len = Object.keys(location.state.oldvalues).length;
        const temp = location.state.oldvalues;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        let diamond_type = "N"
        if(userinfo.type !== "Natural"){
            diamond_type = "L"
        }
        const finalData = `{ "JsonData":  [${data}], "WLDiamondType": "${diamond_type}" ,"page":1,"fancy":"${fancy}" }`;
        // localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search-count`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${localStorage.getItem("authToken")}` 
            },
            data: finalData,
        })
        .then(function(rescount) {
            // setStoneCount(JSON.stringify(rescount.data.data));
            setCountLoader(false)
            setStoneCount(rescount.data.count)
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
                          localStorage.clear();
                          window.location.replace(`${BASE_URL}/logout`);
                      }
        })
        }
        else{
            setStoneCount(location.state.searchresult.length)
            setCountLoader(false)
        }
    },[updatecount])
    
    useEffect(() => {
        if(stoneData.length !== parseFloat(stoneCount) && pagenumber !== 1){
            HandlePagination(pagenumber);
        }
    }, [pagenumber]);
    const onScroll = () => {
        const div = scrollRef.current;
        if (!div) return;
        console.log(Math.round(div.scrollTop + div.clientHeight), "SUMMMMM");
        console.log(div.scrollHeight, "div.scrollHeight");
        if(!infiniteLoader){
            if (Math.round(div.scrollTop + div.clientHeight + 1) >= div.scrollHeight && stoneData.length > 0) {
            setPageNumber(prevPage => prevPage + 1);
        }
        }
    };

    const HandlePagination = (pageno) => {
        setInfiniteLoader(true)
                const fancy = Cookies.get("fancy")?1:0
                window.Nat = location.state.oldvalues;
                let len = Object.keys(location.state.oldvalues).length;
                const temp = location.state.oldvalues;
                let data = "";
                for (let key in temp) {
                    len--;
                    let arr = temp[key];
                    if (len) {
                        if (Array.isArray(arr)) {
                            let str = "";
                            str += "'" + arr.join("','") + "'";
                            data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                        } else {
                            data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                        }
                    } else {
                        if (Array.isArray(arr)) {
                            let str = "";
                            str += "'" + arr.join("','") + "'";
                            data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                        } else {
                            data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                        }
                    }
                }
                let diamond_type = "N"
                if(userinfo.type !== "Natural"){
                    diamond_type = "L"
                }
                const finalData = `{ "JsonData":  [${data}], "WLDiamondType": "${diamond_type}" ,"page":${pageno},"fancy":"${fancy}","sortobj":${JSON.stringify(sortobj)} }`;
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${localStorage.getItem("authToken")}` 
            },
            data: finalData,
        })
        .then(function(res) {
            setInfiniteLoader(false)
            setStoneData([
                ...stoneData,
                ...res.data.data
            ])
        })
        .
        catch(error => {
            setInfiniteLoader(false)
          if(error?.response?.data?.message === "UnAuthorized!"){
                          localStorage.clear();
                          window.location.replace(`${BASE_URL}/logout`);
                      }
        })
    }
    useEffect(() => {
        if(location.state.sortobj){
            setSortObj(location.state.sortobj)
        }
    },[])
    useEffect(() => {
        if(sortobj && sortobj.id){
            setLoader(true)
        const fancy = Cookies.get("fancy")?1:0
                    window.Nat = location.state.oldvalues;
                    let len = Object.keys(location.state.oldvalues).length;
                    const temp = location.state.oldvalues;
                    let data = "";
                    for (let key in temp) {
                        len--;
                        let arr = temp[key];
                        if (len) {
                            if (Array.isArray(arr)) {
                                let str = "";
                                str += "'" + arr.join("','") + "'";
                                data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                            } else {
                                data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                            }
                        } else {
                            if (Array.isArray(arr)) {
                                let str = "";
                                str += "'" + arr.join("','") + "'";
                                data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                            } else {
                                data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                            }
                        }
                    }
                    let diamond_type = "N"
                    if(userinfo.type !== "Natural"){
                        diamond_type = "L"
                    }
                const finalData = `{ "JsonData":  [${data}], "WLDiamondType": "${diamond_type}" ,"page":1,"fancy":"${fancy}","sortobj":${JSON.stringify(sortobj)} }`;
               
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            },
            data: finalData,
        })
            .then(function (res) {
                setLoader(false)
                setStoneData(res.data.data)
                setPageNumber(1)
                history.push({
                    pathname: `/search-result`,
                    state: {
                        searchresult: res.data.data,
                        oldvalues: location.state.oldvalues,
                        page: 1
                    },
                    // state: decompress(res.data.ProductDetails),
                });
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
            })
            .
            catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                                localStorage.clear();
                                window.location.replace(`${BASE_URL}/logout`);
                            }
            })
        }
    },[sortobj])
    useEffect(() => {
        const div = scrollRef.current;
        if (div && !isStockSearch && stoneData.length) {
            div.addEventListener('scroll', onScroll);
            return () => div.removeEventListener('scroll', onScroll);
        }
    }, [scrollRef, stoneData,loader, isStockSearch,infiniteLoader,stoneData]);
    const activeShippSwal =(selectedFlatRows)=>{
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }

        // Check if any of the selected diamonds are already activated
        const alreadyActivated = selectedFlatRows.some(
            (row) => row.original?.custTypeNo === "0"
        );

        if (alreadyActivated) {
            alert("One or more selected diamonds are already activated for shipment");
            return;
        }

        let temptotaldiamonds = 0
        let temptotalprice = 0
        let diamondcarat = 0
        selectedFlatRows.map(value => {
            temptotaldiamonds += 1
            temptotalprice += parseFloat(value.original.supplierPrice)
            diamondcarat += parseFloat(value.original.carat)
            return {
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
            }
        })


        setTotalDiamonds(parseFloat(temptotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice))
        setTotalCarat(parseFloat(diamondcarat))
        setTotalDollarCarat( temptotalprice !== 0 ?  parseFloat(temptotalprice) / parseFloat(diamondcarat) : "00")

        let diamondinfo = selectedFlatRows.map(value => {
            return (
                (value.original)
            )
        })
        setShipStone(diamondinfo)
        setShipPopup(true)

    }


    const removeStones =(selectedFlatRows)=>{
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }

        let temptotaldiamonds = 0
        let temptotalprice = 0
        let diamondcarat = 0
        selectedFlatRows.map(value => {
            temptotaldiamonds += 1
            temptotalprice += parseFloat(value.original.supplierPrice)
            diamondcarat += parseFloat(value.original.carat)
            return {
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
            }
        })


        setTotalDiamonds(parseFloat(temptotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice))
        setTotalCarat(parseFloat(diamondcarat))
        setTotalDollarCarat( temptotalprice !== 0 ?  parseFloat(temptotalprice) / parseFloat(diamondcarat) : "00")

        let diamondinfo = selectedFlatRows.map(value => {
            return (
                (value.original)
            )
        })
        setShipStone(diamondinfo)
        setRemoveStonePopup(true)

    }

    const updateAvailibility =(selectedFlatRows)=>{
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }

        let temptotaldiamonds = 0
        let temptotalprice = 0
        let diamondcarat = 0
        selectedFlatRows.map(value => {
            temptotaldiamonds += 1
            temptotalprice += parseFloat(value.original.supplierPrice)
            diamondcarat += parseFloat(value.original.carat)
            return {
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
            }
        })


        setTotalDiamonds(parseFloat(temptotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice))
        setTotalCarat(parseFloat(diamondcarat))
        setTotalDollarCarat( temptotalprice !== 0 ?  parseFloat(temptotalprice) / parseFloat(diamondcarat) : "00")

        let diamondinfo = selectedFlatRows.map(value => {
            return (
                (value.original)
            )
        })
        setShipStone(diamondinfo)
        setUpdateAvailibility(true)

    }


    const columns = useMemo(() => searchResultColumn(), []);

    
    const globalFilterFunction = (rows, columnIds, filterValue) => {
        return rows.filter(row => {
            const values = Object.values(row.original); // Get all field values of the row
            return values.some(value => 
                String(value).toLowerCase().includes(filterValue.toLowerCase())
            );
        });
    };

    function discount(x){
        
        let orignal_price = parseFloat(x.oRate * x.carat).toFixed(2);
        let carat_price = parseFloat(orignal_price / x.carat).toFixed(2);
        let discount = x.cRap ? parseFloat((carat_price - x.cRap) / (x.cRap) * 100).toFixed(2) : "0.00" 
        return parseFloat(discount).toFixed(2);
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        selectedFlatRows,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data:stoneData,    
            initialState:{ pageSize: 1000000000},
            autoResetExpanded:!infiniteLoader,
            disableSortRemove: true,
            autoResetSelectedRows: !infiniteLoader,
            globalFilter: globalFilterFunction,
            // initialState: {
            //     sortBy: [
            //         {
            //             id: 'carat',
            //             desc: true
            //         }
            //     ]
            // }
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                                
                                <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                            <span  {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" />}
                            </span>
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );
    useEffect(() => {
        const handleResize = () => {

          if (scrollRef.current) {
            // Calculate height in pixels based on 77vh
            const heightInPixels = window.innerHeight * 0.77;
            if (heightInPixels === 535.15){

                scrollRef.current.style.height = `${heightInPixels}px`;
            }else if (heightInPixels > 535.15){
                scrollRef.current.style.height = `${heightInPixels+20}px`;
                
            }else{
                scrollRef.current.style.height = `${heightInPixels-20}px`;

            }
          }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial height
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [scrollRef,loader]);
      const HandleRefreshPage = () => {
        if(!location.state.oldvalues){
            ReloadStockPage()
           }else{
            ReloadPage()
           }
      }
    return (
        <>
            {!loader ?<div className="card card-custom gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title">
                        <span className="card-label font-weight-bolder text-dark">
                            Search Result {countloader?<span className="text-primary">Loading Count...</span>:<span className="text-primary">(Total stone: {stoneCount})</span>}
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        {!isStockSearch?<Sorting sortobj={sortobj} setSortObj={setSortObj}/>:""}
                        {/* <button className="btn btn-primary btn-sm text-nowrap mr-2" onClick={()=>activeShippSwal(selectedFlatRows)}>Active Shipment</button> */}
                        <button className="btn btn-primary btn-sm text-nowrap mr-2" onClick={()=>updateAvailibility(selectedFlatRows)}>Update Availibility</button>
                        <button className="btn btn-warning btn-sm text-nowrap mr-2" onClick={()=>removeStones(selectedFlatRows)}>Remove Stones</button>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2 searchResult">
                                    <PerfectScrollbar
                                        options={perfectScrollbarOptions}
                                        className="scroll"
                                        style={{ maxHeight: "400px", position: "relative" }}
                                    >
                                    {/* <div  className="" > */}
                                        <div>
                                            <label className="checkbox my-2 border p-2">
                                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label> 
                                        </div>
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id} className={`${column.id}`}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    {/* </div> */}
                                    </PerfectScrollbar>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-5 pb-0">
                    {successMsg ? 
                        <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                            <div className="alert-text font-weight-bold">
                               {successMsg}
                            </div>
                        </div>
                    : null }
                    <div className="table-responsive custom-scrollbar w-100 mt-4" ref={scrollRef}>
                        <table className="table table-striped table-hover custom_datatable" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                                {headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(
                                            (column, i) => (
                                                <th key={i} className="text-center align-middle">
                                                    {column.render("Header")}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                    </span>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {stoneData.length !== 0 ? page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={row.id}>
                                            <tr {...row.getRowProps()} key={row.id} className={`${discount(row.original) < (-97.00) ? "bg-danger-o-20" : ""}`}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </React.Fragment>
                                    )
                                }) :
                                <tr>
                                    <td className="text-center align-middle"colSpan="100%">
                                        No data found
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                        {infiniteLoader ? <InfiniteTableSkeleton /> :null}
                        {stoneData.length === parseFloat(stoneCount)?<div className="d-flex align-items-center justify-content-center">No more stones found</div>:""}
                    </div>
                </div>
                <UpdateAvailibility setLoader={setLoader} setSuccessMsg={setSuccessMsg} show={updateAvailibilityPopup} onHide={() => setUpdateAvailibility(false)} data={shipStone} userinfo={userinfo} HandleRefreshPage={HandleRefreshPage}/>
                <ActiveShipping setLoader={setLoader} setSuccessMsg={setSuccessMsg} show={shipPopup} onHide={() => setShipPopup(false)} data={shipStone} userinfo={userinfo} HandleRefreshPage={HandleRefreshPage} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalDollarCarat={totalDollarCarat} totalCarat={totalCarat}/>
                <RemoveStones setLoader={setLoader} setSuccessMsg={setSuccessMsg} show={removeStonePopup} onHide={() => setRemoveStonePopup(false)} data={shipStone} userinfo={userinfo} HandleRefreshPage={HandleRefreshPage} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalDollarCarat={totalDollarCarat} totalCarat={totalCarat} setUpdateCount={setUpdateCount} updatecount={updatecount}/>
            </div>
            : 
                <SearchTableSkeleton />
            }
        </>
    );
};
