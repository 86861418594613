/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../../env_config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEdit, FaSave } from "react-icons/fa";
import { SwalWarn } from "../../popup";

export function TimeZone({ userinfo, subId }) {
    const [swalWarnOpenTz, setSwalWarnOpenTz] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [tz, setTz] = useState(userinfo.timezone ? userinfo.timezone : "Asia/Kolkata");
    const [datetime, setDatetime] = useState(moment());
    const [timezoneView, setTimezoneView] = useState(true);
    const [timezonedata, setTimeZoneData] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios({
            method: "GET",
            url: NODE_API_URL + "/SUPP/fetch-timezone",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            }
        })
            .then((res) => {
                setLoading(false);
                setTimeZoneData(res.data.data);
            })
            .catch((res) => {
                setLoading(false);
                if (res?.response?.data?.message === "UnAuthorized!") {
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
    }, []);

    const saveTimeZone = (tz) => {
        setLoading(true);
        const finalData = {
            timezone: tz,
        };

        axios({
            method: "POST",
            url: NODE_API_URL + "/SUPP/insert-time-zone",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            data: finalData,
        })
            .then((res) => {
                setLoading(false);
                setTimezoneView(true);
                if (res && res.data) {
                    setsuccessMsg(res.data.msg);
                }
                setSwalWarnOpenTz(false);
                const timeId = setTimeout(() => {
                    setsuccessMsg();
                }, 5000);

                return () => {
                    clearTimeout(timeId);
                };
            })
            .catch((res) => {
                setLoading(false);
                if (res?.response?.data?.message === "UnAuthorized!") {
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
    };

    const swalWarnOpenfnTz = (tz) => {
        setSwalWarnOpenTz(true);
        setAlertMessage(`Are you sure want to change timezone to ${tz} ?`);
    };

    useMemo(() => {
        setDatetime(datetime.tz(tz));
    }, [tz, datetime]);

    return (
        <>
            <div className="card card-custom">
                {/* begin::Header */}
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            TimeZone Setting
                        </h3>
                        {!subId ? <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Change your Timezone
                        </span> : ""}
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Form */}
                {timezoneView ?
                    <div className="card-body" style={{ minHeight: "258px" }}>
                        <div className="row">
                            <div className="col-12">
                                {successMsg ? (
                                    <div className="mb-5 alert alert-custom alert-light-success py-3 alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            {successMsg}
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div className="col-md-12 font-size-h6 font-weight-bolder">
                                Selected Time-zone :
                                <span className="font-weight-bold ml-3"> {tz}</span>
                                {!subId ? <OverlayTrigger placement="bottom" overlay={<Tooltip > Click to Edit Timezone </Tooltip>} >
                                    <FaEdit className="font-size-h6 text-primary text-hover-warning ml-2 cursor-pointer" onClick={() => setTimezoneView(false)} />
                                </OverlayTrigger> : ""}
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="card-body" style={{ minHeight: "258px" }}>
                            <div className="row">
                                <div className="col-12">
                                    {successMsg ? (
                                        <div className="mb-5 alert alert-custom alert-light-success py-3 alert-dismissible">
                                            <div className="alert-text font-weight-bold">
                                                {successMsg}
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div className="col-md-7">
                                    <div className="timezone--wrapper">
                                        <select
                                            className="form-control"
                                            value={tz}
                                            onChange={(e) => setTz(e.target.value)}
                                        >
                                            {timezonedata.map((timezone) => (
                                                <option key={timezone.value} value={timezone.value}>
                                                    {timezone.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                    <div className="btn-group">
                                        <button className="btn btn-primary px-8 d-flex align-items-center" disabled={loading} onClick={() => swalWarnOpenfnTz(tz)}>
                                            <FaSave className="font-size-lg mr-2" />
                                            Save
                                            {loading && (
                                                <span className="ml-3 spinner spinner-white"></span>
                                            )}
                                        </button>
                                        <button className="btn btn-danger px-8 d-flex align-items-center" disabled={loading} onClick={() => setTimezoneView(true)}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <SwalWarn show={swalWarnOpenTz} message={alertMessage} onHide={() => setSwalWarnOpenTz(false)} onClose={() => setSwalWarnOpenTz(false)} responseOnOk={() => saveTimeZone(tz)} />
        </>
    );
}