import React,{useEffect} from "react";
import { Field } from "formik";

export const Shortcut = ({formik}) => {
    const ShortcutData = ([
        {
            id: 1,
            shortcut:"3EX"
        },
        {
            id: 2,
            shortcut:"3EXNON"
        },
        {
            id: 3,
            shortcut:"3VG+"
        },
        {
            id: 5,
            shortcut:"100% EYECLEAN"
        }
    ]);
    const SetShortcut = (value) => {

        //    formik.setFieldValue("shortcut","") 
        function removeItem(arr, value) {
            return arr.filter(val => {
                if(val !== value){
                    return val
                }
            })
          }
          function removeDuplicates(arr) {
            return arr.filter((item,
                index) => arr.indexOf(item) === index);
        }
           if(value === "3EX"){
            formik.setFieldValue("Polish",[]) 
                formik.setFieldValue("Cut",[]) 
                formik.setFieldValue("Symmetry",[]) 
                formik.setFieldValue("Fluorescence_Intensity",[])
                formik.setFieldValue("eyeclean",[])  
            formik.setFieldValue("Polish",["ID","EX"]) 
                formik.setFieldValue("Cut",["ID","EX"]) 
                formik.setFieldValue("Symmetry",["ID","EX"])
           }
           else if(value === "3EXNON"){
            formik.setFieldValue("Polish",[]) 
                formik.setFieldValue("Cut",[]) 
                formik.setFieldValue("Symmetry",[]) 
                formik.setFieldValue("Fluorescence_Intensity",[])
                formik.setFieldValue("eyeclean",[])  
            formik.setFieldValue("Polish",["ID","EX"]) 
                formik.setFieldValue("Cut",["ID","EX"]) 
                formik.setFieldValue("Symmetry",["ID","EX"]) 
                formik.setFieldValue("Fluorescence_Intensity",["NON"])    
           }
           else if(value === "3VG+"){
            formik.setFieldValue("Polish",[]) 
                formik.setFieldValue("Cut",[]) 
                formik.setFieldValue("Symmetry",[]) 
                formik.setFieldValue("Fluorescence_Intensity",[])
                formik.setFieldValue("eyeclean",[])      
            formik.setFieldValue("Polish",["ID","VG","EX"]) 
                formik.setFieldValue("Cut",["ID","VG","EX"]) 
                formik.setFieldValue("Symmetry",["ID","VG","EX"]) 
           }
           else if(value === "100% EYECLEAN"){
            formik.setFieldValue("eyeclean",["YES"])           
            formik.setFieldValue("Polish",[]) 
                formik.setFieldValue("Cut",[]) 
                formik.setFieldValue("Symmetry",[]) 
                formik.setFieldValue("Fluorescence_Intensity",[])         
           }
           setTimeout(() => {
            formik.setFieldValue("shortcut",[])
           },1000)
        }

    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">SHORTCUT</p>
                </div>
                <div className="col-lg-10">
                    {ShortcutData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="shortcut" id={`${item.shortcut}_shortcut`} value={item.shortcut} onClick={(e) =>SetShortcut(e.target.value)}/>
                            <div className="shortcutBox selectBox">
                                <span>{item.shortcut}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};