import React from "react";
import {BASE_URL_FILE} from "../../../../env_config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import { FaExternalLinkAlt } from 'react-icons/fa';

// function jugaadDate(x) {
//     // const tempDateNew = moment.tz(x, "America/St_Johns").format("YYYY-MM-DD HH:mm:ss");
//     // const dateNew = moment.tz(tempDateNew, "America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss");
//     // return dateNew

//     let storedDate = new Date(x);
//     storedDate = new Date(storedDate.getTime() - (330 * 60 * 1000));
//     return storedDate
// }

export const InvalidDiamondColumn = (userinfo,editPerm,subId,downloadExcel,invData) => [
    {
        Header: "File Name",
        accessor: "info",
        minwidth: 50,
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    {row.row.original.uploadType === "File"?  
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >{row.row.original.info}</Tooltip>} >
                            <span className="text-truncate d-inline-block" style={{ maxWidth: "80px" }}>{row.row.original.info}</span>
                        </OverlayTrigger>
                    : "N/A"}
                </>
            )
        }
    },
    {
        Header: "Upload Type",
        accessor: "uploadType",
        className: "text-center align-middle",
    },
    {
        Header: "Stock Status",
        accessor: (d) => {
            return (
                <>
                   {d.stockStatus === "LIVE"?<span className="text-success">{d.stockStatus}</span>
                   :d.stockStatus === "Expired"?<span className="text-danger">{d.stockStatus}</span>
                   :d.stockStatus === "Freeze"?<span className="text-danger">{d.stockStatus}</span>
                   :d.stockStatus === "InActive"?<span className="text-danger">{d.stockStatus}</span>
                   :d.stockStatus === "Error"?<span className="text-danger">{d.stockStatus}</span>
                   :""}
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Date and Time",
        // accessor: "createdDate",
        accessor: (d) => {
            return (
                <>
                    {/* {  d.createdDate ? moment.tz(d.createdDate, "Etc/GMT").format("YYYY-MM-DD HH:mm:ss") : "-"} */}
                    {  d.createdDate ? d.createdDate : "-"}
                </>
            );
        },
        className: "text-center text-nowrap align-middle",
    },
    
    {
        Header: "Total Diamonds",
        accessor: "noOfStone",
        className: "text-center align-middle",
    },
    {
        Header: "Valid Diamonds",
        accessor: "active",
        className: "text-center align-middle",
    },
    {
        Header: "Invalid Diamonds",
        accessor: (d,i) => {
            return (
                <>
                   <span className="text-danger">
                    {i === 0 ?
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >Click to download Excel</Tooltip>} >
                            <span className="cursor-pointer" onClick={()=>downloadExcel(invData)}>{d.inactive} <FaExternalLinkAlt className="pl-1"/></span>
                        </OverlayTrigger>
                     : d.inactive}
                   </span>
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Action",
        accessor: (d) => {
            return (
                <>
                {editPerm || !subId ?
                    <> {d.uploadType === "File" && d.stockStatus !== "Expired"?  <a href={`${BASE_URL_FILE}/assets/supplier_upload_file/${d.info}`} className="btn btn-sm btn-primary">Download</a> : "N/A"}</>
                : "-"}
                </>
            );
        },
        className: "text-center align-middle",
    },
    
];
