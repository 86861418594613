import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
// import SVG from "react-inlinesvg";
import moment from "moment-timezone";
import { FaInfoCircle } from 'react-icons/fa';
import {HiOutlineBadgeCheck} from "react-icons/hi"
import { FcShipped } from "react-icons/fc";
import AdvancedTag from "../popup/AdvancedTag";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const SalesHistoryColumn = (userinfo) => [

   
    {
        Header: "Status",
        accessor: (d) => {
            return (
                <>
                    {d.vCustomer === "12"  ? 
                        <div className="d-flex align-items-center justify-content-center">
                            <OverlayTrigger placement="bottom" overlay={<Tooltip > Delivered </Tooltip>} >
                                <HiOutlineBadgeCheck  className="font-size-h3 text-success"/> 
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Ship Stone</Tooltip>}
                            >
                                <FcShipped className="ml-2 font-size-h4"/>
                            </OverlayTrigger>
                        </div>
                    : 
                        <OverlayTrigger placement="bottom" overlay={<Tooltip > Delivered </Tooltip>} >
                            <HiOutlineBadgeCheck  className="font-size-h3 text-success"/> 
                        </OverlayTrigger>
                    }
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Bill/Payment Status",
        accessor: (d) => {
            return (
                <>
                {d.orderStatus === "Invoice Pending" ?
                    <>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >
                            <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.billStatusDate ? d.billStatusDate : "-"}</p>
                            <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.billCreatedTime ? d.billCreatedTime : "-"}</p>
                            <p className="text-nowrap"><strong>Bill No. : </strong>{d.billNo ? d.billNo :"-"}</p>
                            <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.billDueDate ? d.billDueDate : "-"}</p>
                            <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.billPaymentDate ? d.billPaymentDate : "-"}</p>
                        </Tooltip>} >
                            <span className="label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap">{d.orderStatus} <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                        </OverlayTrigger>
                    </>
                : d.orderStatus === "Invoice Recevied" ? 
                    <>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >
                            <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.billStatusDate ? d.billStatusDate : "-"}</p>
                            <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.billCreatedTime ? d.billCreatedTime : "-"}</p>
                            <p className="text-nowrap"><strong>Bill No. : </strong>{d.billNo ? d.billNo :"-"}</p>
                            <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.billDueDate ? d.billDueDate : "-"}</p>
                            <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.billPaymentDate ? d.billPaymentDate : "-"}</p>
                        </Tooltip>} >
                            <span className="label label-lg label-outline-primary label-pill label-inline bg-primary-o-20 font-weight-bold text-nowrap">{d.orderStatus} <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                        </OverlayTrigger>
                    </>          
                :  d.orderStatus === "Invoice Paid" ? 
                    <>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip >
                            <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.pickupDoneDate ? d.pickupDoneDate : "-"}</p>
                            <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.billCreatedTime ? d.billCreatedTime : "-"}</p>
                            <p className="text-nowrap"><strong>Bill No. : </strong>{d.billNo ? d.billNo :"-"}</p>
                            <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.billDueDate ? d.billDueDate : "-"}</p>
                            <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.billPaymentDate ? d.billPaymentDate : "-"}</p>
                        </Tooltip>} >
                            <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">{d.orderStatus} <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                        </OverlayTrigger>
                    </>  
                : <>
                <OverlayTrigger placement="bottom" overlay={<Tooltip >
                    <p className="text-nowrap"><strong>Pickup Done Date : </strong>{  d.pickupDoneDate ? d.pickupDoneDate : "-"}</p>
                    <p className="text-nowrap"><strong>Bill Received Date : </strong>{  d.billCreatedTime ? d.billCreatedTime : "-"}</p>
                    <p className="text-nowrap"><strong>Bill No. : </strong>{d.billNo ? d.billNo :"-"}</p>
                    <p className="text-nowrap"><strong>Payment Due Date : </strong>{  d.billDueDate ? d.billDueDate : "-"}</p>
                    <p className="text-nowrap"><strong>Payment Done Date : </strong>{  d.billPaymentDate ? d.billPaymentDate : "-"}</p>
                </Tooltip>} >
                    <span className="label label-lg label-outline-warning label-pill label-inline bg-warning-o-20 font-weight-bold text-nowrap">{d.orderStatus} <FaInfoCircle className="ml-2 cursor-pointer" /></span>
                </OverlayTrigger>
            </> }
                </>
            );
        },
        className: "text-center align-middle orderStatus",
    },
    {
        Header: "Pickup Date",
        // accessor: "pickupDate",
        accessor: (d) => {
            return (
                <>
                    {  d.pickupDate ? d.pickupDate : "-"}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Order ID",
        accessor: "cgi",
        className: "text-center align-middle",
    },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.cShape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.cShape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-2 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`text-primary small ml-3`}>
                                {d.diamondType}-{d.parcelId}-{d.splitId}{d.cgSplitId ?<>/{d.cgSplitId}</> : ""}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.lengthMm} - {d.widthMm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor}{" "}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.image &&  d.image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={d.image}
                                width="50px"
                                onError={e => {toAbsoluteUrl(
                                    e.currentTarget.src = `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "W" || d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy"  ? "NF" : "N" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.loatNO}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.certificate_link
                                }
                            >
                                {d.lab}-{d.certiNo}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor === "fancy" ? (
                                <>
                                    {d.fIntensity}{" "}{d.fOvertone}{" "}{d.fColor}{" "}
                                </>
                            ) : (
                                <>{d.cColor}{" "}</>
                            )}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },
   {
           Header: "Advanced tags",
           accessor: (d) => (
               <>
                   <AdvancedTag {...d}/>
               </>
           ),
           className: "text-center align-middle",
       },
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    $ {numberWithCommas(parseFloat(d.aPrice))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT: <span style={{fontFamily:"sans-serif"}}> </span> {numberWithCommas(d.aPrice / d.cWeight)}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
        
    },
   
    {
        Header: "Location",
        className: "text-center align-middle",
        accessor: "firstLocation",
    },
    {
        Header: "Exchange Rate",
        className: "text-center align-middle",
        accessor: (d) => {
            return (
                <>
                   {d.curInr <= 1 ?  "$" : <span style={{fontFamily:"sans-serif"}}>₹</span>}{parseFloat(d.curInr).toFixed(2)}
                </>
            );
        },
    },
    {
        Header: "Local Price",
        className: "text-right text-nowrap align-middle",
        accessor: (d) => {
            return (
                <>
                    {d.firstLocation === 'Mumbai' || d.firstLocation === 'Surat' ? 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in INR</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span> {numberWithCommas(d.aPrice * d.curInr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        <span style={{fontFamily:"sans-serif"}}>₹</span>/CT: <span style={{fontFamily:"sans-serif"}}> ₹</span> {numberWithCommas(d.curInr * (d.aPrice / d.cWeight))}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                            
                        </> : 
                        <>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip >Price in USD</Tooltip>} >
                               <div>
                                    <span className="font-weight-bolder text-primary">
                                        $ {numberWithCommas(d.aPrice * d.curInr)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        $/CT: <span style={{fontFamily:"sans-serif"}}> </span> {numberWithCommas(d.curInr * (d.aPrice / d.cWeight))}
                                    </span>
                                </div>
                               
                            </OverlayTrigger>
                        </> 
                    }
                </>
            );
        },
       
    }
];
