import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
import { ImagePopup } from "../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../widgets/tiles/VideoPopUp";
import { FaAward } from "react-icons/fa";
import { FcShipped } from "react-icons/fc";

function total(x) {
    let total = parseFloat(x.oRate * x.carat).toFixed(2);
    return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function discount(x){
        
    let orignal_price = parseFloat(x.oRate * x.carat).toFixed(2);
    let carat_price = parseFloat(orignal_price / x.carat).toFixed(2);
    let discount = x.rapRate ? parseFloat((carat_price - x.rapRate) / (x.rapRate) * 100).toFixed(2) : "0.00" 
    return parseFloat(discount).toFixed(2);
}


export const searchResultColumn = () => [
    
    {
        Header: "Availability",
        accessor: "availability",
        className: "text-center align-middle",
        Cell: (row) => (
            <>
                {row.row.original.availability === "Guaranteed" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Guaranteed</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-success" >G</span>
                    </OverlayTrigger>
                : row.row.original.availability === "Memo" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Memo</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-primary" >M</span>
                    </OverlayTrigger>
                : row.row.original.availability === "Hold" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Hold</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-primary" >H</span>
                    </OverlayTrigger> 
                : row.row.original.availability === "Not Available" 
                ?   <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Not Available</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-danger" >NA</span>
                    </OverlayTrigger>  
                : row.row.original.availability === "Subject to Prior sale" 
                ?   <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Subject to Prior sale</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-warning" >S</span>
                    </OverlayTrigger>
                : "-"}
            </>
        ),
    },
    {
        Header: "Stone ID",
        accessor: "loatNo",
        className: "text-center align-middle",
        Cell: (row) => (
            <>
                {row.row.original.custTypeNo === "0" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Activated Ship Stone</Tooltip>}
                    >
                       <span className="text-primary font-weight-bold"><FcShipped className="mr-2 font-size-h4"/>{row.value}</span>
                    </OverlayTrigger>
                : row.value
                }
                
            </>
        ),
    },
    {
        Header: "Country" ,
        accessor: "location",
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    {row.value ? (
                        <OverlayTrigger
                            placement="top"
                            className="text-capitalize"
                            overlay={
                                <Tooltip id="tooltip">{row.value}</Tooltip>
                            }
                        >
                            <img
                                className="img-fluid flagIco"
                                alt={row.value}
                                src={toAbsoluteUrl(
                                    `/media/map/${row.value.toLowerCase()}.png`
                                )}
                            />
                        </OverlayTrigger>
                    ) : null}
                </>
            );
        },
    },
    {
        Header: "Media" ,
        accessor: (d) => (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    {d.image ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Image</Tooltip>}
                            >
                                <ImagePopup
                                    Image={d.image}
                                    Shape={d.shape}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.image}><BsCardImage/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block"  style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.video ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Video</Tooltip>}
                            >
                                <VideoPopup
                                    ProductId={d.id}
                                    WLDiamondType={d.diamondType}
                                    Certi_No={d.certificate}
                                    video={d.video}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.video}><BsFillCameraVideoFill/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block" style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.certificate ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                }
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        d.certificate_link
                                    }
                                >
                                    <FaAward />
                                </a>
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block" style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: "Shape",
        accessor: "shape",
        className: "text-center align-middle p-2",
        sortMethod: (a, b) => a.localeCompare(b),
        Cell: (row) => (
            <>
                <SVG
                    width="22px"
                    height="22px"
                    src={toAbsoluteUrl(
                        `/media/shape/svg_shape/${row.row.original.shape.toLowerCase()}.svg`
                    )}
                />
                <div className="small">{row.row.original.shape}</div>
            </>
        ),
    },
    {
        Header: "Carat",
        accessor: "carat",
        // sortMethod: (a, b) => Number(a) - Number(b),
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{parseFloat(row.value).toFixed(2)}</>;
        },
        sortType: (rowA, rowB) => {
            if (rowB.original.carat > rowA.original.carat) return 1;
            if (rowA.original.carat > rowB.original.carat) return -1;
        }
    },
    {
        Header: "Color",
        accessor: "color",
        minwidth: 50,
        className: "text-center align-middle",
        sortMethod: (a, b) => a.localeCompare(b),
        Cell: (row) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {row.row.original.color}
                        </Tooltip>
                    }
                >
                    <span
                        className="d-inline-block text-truncate text-dark"
                        style={{ maxWidth: "80px" }}
                    >
                        {row.row.original.color}
                    </span>
                </OverlayTrigger>
            </>
        ),
    },
    {
        Header: "Clarity",
        accessor: "clarity",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Cut",
        accessor: "cut",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Polish",
        accessor: "polish",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Symmetry",
        accessor: "symmetry",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Fluo.",
        accessor: "fluorescence",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Lab",
        accessor: "lab",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Certificate",
        accessor: "certificate_link",
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary mx-2 text-left"
                        href={row.row.original.certificate_link}
                    >
                        {row.row.original.certificate}
                    </a>
                </>
            )
        },
        
    },
    {
        Header: "disc %",
        className: "text-right align-middle pr-5",
        Cell: (row) => (
            <>
               <span className={`${parseFloat(row.row.original.supplierDiscount) >= 0  ? 'text-success' : 'text-danger'} pl-2`}> {row.row.original.supplierDiscount}</span>
            </>
        ),
        disableSortBy: true,
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: "$/CT",
        className: "text-right align-middle pr-5",
        accessor: "supplierRate",
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => (
            <>
                {
                    <span> ${parseFloat(row.value).toFixed(2) }</span>
                }
            </>
        ),
    },
    {
        Header: "Total",
        className: "text-right align-middle pr-5",
        Cell: (d) => {
            return (
                <>
                  ${parseFloat(d.row.original.supplierPrice).toFixed(2)}
                </>
            );
        },
        disableSortBy: true,
    }
];
