/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form,Field } from "formik";
import { useHistory ,useLocation} from "react-router-dom";
// import { Modal, Button } from "react-bootstrap";
// import { Paper, Tabs, Tab, Typography } from "@material-ui/core/";
import { FaSearch } from "react-icons/fa";
import PropTypes from "prop-types";
import axios from "axios";
import { BASE_URL, NODE_API_URL} from "./../../../env_config";
// import { GetCookies } from "../../_helpers/Cookies";
// import languages from '../../_helpers/languages.json'
import Cookies from "js-cookie"
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import { Basic } from "./searchcomponent/Basic";
import { Tinge } from "./searchcomponent/Tinge";

import { Additional } from "./searchcomponent/Additional";
import { Preference } from "./searchcomponent/Preference";
import { Parameter } from "./searchcomponent/Parameter";

export const SearchDiamond = ({userinfo,subId}) => {
    const location = useLocation()
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    // console.log(location,"locationlocation")
    const [open, setOpen] = React.useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [zeroSuccessOpen, setZeroSuccessOpen] = React.useState(false);
    const [filterCriteria, setFilterCriteria] = React.useState(false);
    const [finalFetchData, setFinalFetchData] = React.useState([]);

    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    function handleClose(event, reason) {
        setOpen(false);
        setZeroSuccessOpen(false);
        setFilterCriteria(false);
    }

    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        setIsSubmitting(true)
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        setIsSubmitting(false)
    };

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };

    const history = useHistory();
   
    const [data, setData] = React.useState([]);
    const [stoneCount, setStoneCount] = React.useState([]);
    const [loading, setLoading] = React.useState(false);


    function handelStoneCountError() {
        setOpen(true);
        disableLoading();
    }

    function handelFilterCriteria() {
        setFilterCriteria(true);
        disableLoading();
    }
    function handelRest(newValue) {
        setTimeout(() => {
            window.location.reload(true);
            window.glob = newValue;
        }, 500);
    }

    function handleData(values,oldvalues) {
        const fancy = Cookies.get("fancy")?1:0
        window.Nat = values;
        let len = Object.keys(values).length;
        const temp = values;
        if(temp.Certi_No){
            temp.Certi_No = temp.Certi_No.replaceAll("N","").replaceAll("L","").replaceAll("-","")
        }
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        let diamond_type = "N"
        if (userinfo.type !== "Natural") {
            diamond_type = "L"
        }
        const finalData = `{ "JsonData":  [${data}], "WLDiamondType": "${diamond_type}" ,"page":1,"fancy":"${fancy}" }`;
       
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/search`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${localStorage.getItem("authToken")}` 
            },
            data: finalData,
        })
        .then(function(res) {
            if(!res.data.data.length){
                setZeroSuccessOpen(true);
                disableLoading();
                return
            }
            
            history.push({
            pathname: `/search-result`,
            state: {
                searchresult:res.data.data,
                page:1,
                oldvalues:oldvalues
            },
            });
        })
        .
        catch(error => {
            disableLoading();
            if(error?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
        })
        setFinalFetchData(finalData);
    }
    React.useEffect(
        () => window.localStorage.setItem("fetchData", finalFetchData),
        [finalFetchData]
    );

    React.useEffect(
        () => window.localStorage.setItem("stoneCount", stoneCount),
        [stoneCount]
    );
    React.useEffect(() => {
        document.title = "Search Diamonds | Diamonds on Call - Suppliers Center";
        let isMounted = true;
        // console.log(subId)
        setOpenLoader(true)
        if (subId){
            // setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":3
            };
            axios({
                method: "GET",
                url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                params: permiFetchData,
              }).then((res) => {
                  if (res && res.data) {
                      isMounted && setEditPerm(res.data.data[0].editPermission);
                      isMounted && setReadPerm(res.data.data[0].readPermission);                  }
              }).catch((res) => {
                  if(res?.response?.data?.message === "UnAuthorized!"){
                      localStorage.clear();
                      window.location.replace(`${BASE_URL}/logout`);
                  }
              });
          }
        setOpenLoader(false)
    }, [subId]);
    return (
        <>
            {(readPerm && editPerm ) || !subId ?
                <div className="row natTab">
                    <div className="col-12">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{}}
                            onSubmit={async (values, { setSubmitting }) => {
                                // console.log("values",values)
                                enableLoading();
                                let diamond_type = "N"
                                if(userinfo.type !== "Natural"){
                                    diamond_type = "L"
                                }
                                let oldvalues = values
                                oldvalues.diamond_type = diamond_type
                                Object.keys(values).forEach((key) => {
                                    if (
                                        values[key] === "" ||
                                        values[key] === null ||
                                        values[key].length === 0
                                    ) {
                                        delete values[key];
                                    }
                                });
                                setTimeout(() => {
                                    handleData(values,oldvalues);
                                }, 1000);
                            }}
                        >
                            {(formik, handleSubmit) => (
                                <>
                                    <Form>
                                        <div className="card card-custom card-sticky gutter-b">
                                            <div className="card-header">
                                                <div className="card-title">Search Diamonds</div>
                                                <div className="card-toolbar d-flex flex-nowrap">
                                                    <div className="form-group mb-0 mr-2 w-50">
                                                        <div className="input-icon input-icon-right">
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="Certi_No"
                                                                id="Certi_No"
                                                                placeholder="certificate number.."
                                                                autoComplete="off"
                                                            />
                                                            <span><FaSearch /></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-0 w-50">
                                                        <div className="input-icon input-icon-right">
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="Stone_ID"
                                                                id="Stone_ID"
                                                                placeholder="Stone number.."
                                                                autoComplete="off"
                                                            />
                                                            <span><FaSearch /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 mb-10">
                                                        <Basic userinfo={userinfo} formik={formik} option={location.option}/>
                                                    </div>
                                                    <div className="col-12 mb-10">
                                                        <Preference formik={formik}/>
                                                    </div>
                                                    <div className="col-12 mb-10">
                                                        <Parameter formik={formik}/>
                                                    </div>
                                                    {/* <div className="col-12 mb-10">
                                                        <Tinge />
                                                    </div> */}
                                                    {/* <div className="col-12 mb-10">
                                                        <Preference1 />
                                                    </div> */}
                                                    <div className="col-12 mb-10">
                                                        <Additional userinfo={userinfo}  formik={formik}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="card-footer bg-white natTab text-center sticky-top"
                                                style={{ bottom: "0" }}
                                            >
                                                <button
                                                    type="submit"
                                                    className={`btn btn-primary px-10 font-weight-bold mr-2 btn-lg ${isSubmitting ? "cursor-na" : ""}`}
                                                    disabled={isSubmitting}
                                                    onClick={handleSubmit}
                                                >
                                                    {isSubmitting
                                                        ? "Please wait..."
                                                        : "Search"}
                                                    
                                                </button>
                                                <Backdrop
                                                    sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                    // sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                                    open={openLoader}
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Backdrop>
                                                <button
                                                    type="reset"
                                                    onClick={handelRest}
                                                    disabled={isSubmitting}
                                                    className={`btn font-weight-bold btn-danger btn-lg px-10 ${isSubmitting ? "cursor-na" : ""}`}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    </div>
                </div> 
            :
                <div className="card card-custom gutter-b">
                    <div className="card-header py-3 mb-3">
                        <div className="card-title">
                            <span className="card-label font-weight-bolder text-dark">
                                Search Diamond
                            </span>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        You do not have permission to access, Please contact your administrator for help
                    </div>
                </div>
            }  
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={`${stoneCount} Stones found Please Narrow Your Search to 5000`}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`${stoneCount} Stones found`}
                />
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={zeroSuccessOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="warning"
                    message={`0 Stones found please try again`}
                />
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={filterCriteria}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={`Filter criteria is compulsory for Stone search`}
                />
            </Snackbar>
        </>
    );
};
