
import React ,{useState} from 'react';
import { makeStyles ,Paper, InputBase, IconButton,CircularProgress,Backdrop,Snackbar,SnackbarContent} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import red from "material-ui/colors/red";
import clsx from "clsx";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { NODE_API_URL ,BASE_URL} from '../../../../../../env_config';
import Cookies from "js-cookie"
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    boxShadow:'0 1px 2px rgba(0, 0, 0, .14), 0 0 2px rgba(0, 0, 0, .12)!important',
    backgroundColor: '#fff',
    // border:'solid 1px #424242',
    height: '36px',
    borderRadius:"25px"
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontWeight: 500,
    color : "#424242"
  },
  iconButton: {
    padding: 5,
    color : "#424242"
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  error: {
    backgroundColor: red[600],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor:"#ffffffe6!important"
  },
  noScroll: {
      overflow: 'hidden'
  }
}));

export function Searchbar() {
  const classes = useStyles();
  const [search,setSearch] = useState()
  const [openLoader, setOpenLoader] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const history = useHistory();

  function handleClose(event, reason) {
    setAlertOpen(false);
}
  const enableLoading = () => {
    // setLoading(true);
    setOpenLoader(true);
    setIsSubmitting(true)
  };

  const disableLoading = () => {
    // setLoading(false);
    setOpenLoader(false);
    setIsSubmitting(false)
  };
  function MySnackbarContentWrapper(props) {
    const classes = useStyles();
    const { className, message, onClose, variant, ...other } = props;

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    {message}
                </span>
            }
            {...other}
        />
    );
}
    const SearchDiamond = () => {
        if (search.length > 0) { // Ensure search is not empty
            enableLoading();
            const finalData = {
                "Loat_NO": search
            };
            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/search-by-stock`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                data: finalData,
            })
            .then(function (res) {
                if (res.data.data.length === 0) {
                    disableLoading();
                    setAlertOpen(true);
                    setSearch([]);
                } else {
                    disableLoading();
                    history.push({
                        pathname: "/search-result/stock-search",
                        state: {
                            searchresult: res.data.data,
                            oldvalues: null,
                            finalData:finalData
                        },
                    });
                    setSearch([]);
                }
            })
            .catch(error => {
                disableLoading();
                if (error?.response?.data?.message === "UnAuthorized!") {
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
        }
    };

  const handleInputChange = (e) => {
        let value = e.target.value;

        // Replace multiple spaces with a single space
        value = value.replace(/\s+/g, ' ');

        // Replace spaces with commas
        value = value.replace(/\s/g, ',');

        // Replace consecutive commas with a single comma
        value = value.replace(/,+/g, ',');

        // Remove leading and trailing commas if present
        value = value.replace(/^,|,$/g, '');

        // Split the string into an array and remove empty values
        const searchArray = value.split(',').filter(item => item.trim() !== '');

        // Update state with the array
        setSearch(searchArray);
    };

  return (
    <>
      <Paper className={classes.root}>
        <InputBase
          className={`${classes.input} ${isSubmitting ? "cursor-na" : ""}`}
          placeholder="Search by Certificate Number or Stock ID"
          inputProps={{ 'aria-label': 'Search by Certificate Number or Stock ID' }}
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => e.keyCode === 13?SearchDiamond():""}
          value={search}
        />
        <IconButton className={`${classes.iconButton} ${isSubmitting ? "cursor-na" : ""}`}aria-label="Search" onClick={SearchDiamond} disabled={isSubmitting}>
          <SearchIcon/>
        </IconButton>
        <Backdrop className={classes.backdrop} open={openLoader} >
            <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
        </Backdrop>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="error"
          message={`No Stock Found`}
        />
      </Snackbar>
    </>
  );
}