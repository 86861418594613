/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaRegClock } from "react-icons/fa";
import { Link } from "react-router-dom";

export function HoldreqTile({holdRequestCount,userinfo}) {
  return (
    <>
      <div className={`card card-custom  holdreq card-stretch gutter-b`} style={{ height: "120px" }} >
        {userinfo.isPending === 1 ?
          <>
            <div >
              <div className="card-body">
                <div className="text-white font-weight-bolder font-size-h2 mt-3"> {holdRequestCount? holdRequestCount: "0"} </div>
                <span className="text-light font-weight-bold font-size-lg mt-1"> No. of Stones in hold request</span>
                <div className="bgIcon">
                  <FaRegClock />
                </div>
              </div>
            </div>
          </>
        :
        <Link  to="/order-request" >
          <div className="card-body">
            <div className="text-white font-weight-bolder font-size-h2 mt-3"> {holdRequestCount? holdRequestCount: "0"} </div>
            <span className="text-light font-weight-bold font-size-lg mt-1"> No. of Stones in hold request</span>
            <div className="bgIcon">
              <FaRegClock />
            </div>
          </div>
        </Link>
        }
      </div>
    </>
  );
}
