import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const renderEyecleanLabel = (eyeC) => {

    return (
        <span className="advTag ml-2 mb-2">
            {eyeC && eyeC.toLowerCase() === "yes" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                100% Eye Clean
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
            {eyeC && eyeC.toLowerCase() === "no" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                No Eye Clean
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
        </span>
    );
};
export default renderEyecleanLabel;