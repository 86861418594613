import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../env_config";
import { toAbsoluteUrl } from "../../_helpers";
import AdvancedTagSearch from "./AdvancedTagSearch";

export const RemoveStones = (props) => {
    const [loading, setLoading] = useState(false);
    // console.log(props.data)
    const hideModel = () => {
        props.onHide();
    };

    const RemoveStonesConfirm = (props) =>{
        props.setLoader(true)
        let diamondCerti = props?.data.map(value => {
            return (
                (value.certificate)
            )
        })

        const finalData = {
            "Certi_NO":diamondCerti,
        };
        // console.log("finalData",finalData)
        axios({
            method: "DELETE",
            url: `${NODE_API_URL}/SUPP/delete-stone`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        })
        .then((res) => {
            setLoading(false);
            // console.log(res);
            if (res && res.data) {
                props.setSuccessMsg("stone deleted successfully")
                props.HandleRefreshPage()
                props.setUpdateCount(!props.updatecount)
                setTimeout(() => {
                    props.setSuccessMsg(null)
                }, 10000);
                props.onHide();
            }
        })
        .catch((error) => {
            // console.log(error, "ERROR");
            setLoading(false);
            if(error?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
              }
        });
    }

    function total(x) {
        let total = parseFloat(x.oRate * x.carat).toFixed(2);
        return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header className="">
                    <Modal.Title>Are you sure you want to Remove Below Stones ?
                        <div className="small font-size-sm font-carat-bold text-primary">
                            Total diamonds: {props.totalDiamonds} diamonds |
                            Total Carat :{parseFloat(props.totalCarat).toFixed(2)} |
                            Total $/CT: ${parseFloat(props.totalDollarCarat).toFixed(2)} |
                            Total Payable: ${parseFloat(props.totalPrice).toFixed(2)}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {props?.data ? <div className="col-12 border-bottom">
                            <div className="table-responsive custom-scrollbar w-100 mt-4">
                                <table className="table" >
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center text-nowrap align-middle">
                                                Diamond Detail
                                            </th>
                                            <th className="text-center text-nowrap align-middle">
                                                Advanced tags
                                            </th>
                                            <th className="text-center text-nowrap align-middle">
                                                Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data.map(value => (
                                            <tr >
                                                <td className="text-center align-middle">
                                                    <div className="row">
                                                        <div className="col-2 text-center p-0">
                                                            {value.image && value.image !== "null" ? (
                                                                <img
                                                                    className="img-fluid mr-2"
                                                                    alt={value.shape}
                                                                    src={value.image}
                                                                    width="50px"
                                                                    onError={toAbsoluteUrl(
                                                                        `/media/shape/${value.shape ? value.shape.toLowerCase() : ""}-no.png`
                                                                    )}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="img-fluid mr-2"
                                                                    alt={value.shape}
                                                                    src={toAbsoluteUrl(
                                                                        `/media/shape/${value.shape ? value.shape.toLowerCase() : ""}-no.png`
                                                                    )}
                                                                    onError={e => {
                                                                        e.currentTarget.src = toAbsoluteUrl(
                                                                            `/media/shape/noimage.jpg`
                                                                        )
                                                                    }}
                                                                    width="50px"
                                                                    style={{ backgroundColor: "#f4f4f4" }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="col-10 pl-2 p-0 text-left">
                                                            <div className={`mb-1 ${value.diamondType === "W" || value.diamondType === "N" ? "natTab" : "labTab"}`}>
                                                                <span className={`${value.diamondType === "W" || value.diamondType === "N" ? value.isfancy ? "F" : "W" : value.isfancy ? "LF" : "L"}_diamond mr-2 small`}>
                                                                    {value.diamondType === "W" || value.diamondType === "N" ? value.isfancy ? "NF" : "N" : value.isfancy ? "LF" : "L"}
                                                                </span>
                                                                <span className={`text-primary small ml-3`}>
                                                                    {value.loatNo}
                                                                </span>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="text-primary mx-2 small"
                                                                    href={
                                                                        value.certificate_link
                                                                    }
                                                                >
                                                                    {value.lab}-{value.certificate}
                                                                </a>
                                                            </div>

                                                            <span className="text-dark font-weight-bold d-block">
                                                                {value.shape}{" "}
                                                                {(value.carat)?.toFixed(2)}Ct.{" "}
                                                                {value.color === "fancy" ? (
                                                                    <>
                                                                        {value.fancy_intensity}{" "}{value.fancy_overtone}{" "}{value.fancy_color}{" "}
                                                                    </>
                                                                ) : (
                                                                    <>{value.color}{" "}</>
                                                                )}
                                                                {value.clarity}{" "}{value.cut}{" "}{value.polish}{" "}{value.symmetry}{" "}
                                                                {value.fluorescence}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center align-middle">
                                                    <AdvancedTagSearch {...value} />
                                                </td>
                                                <td className="text-center align-middle">
                                                    <span className="font-weight-bolder text-primary">
                                                        ${parseFloat(value.supplierPrice)?.toFixed(2)}
                                                    </span>{" "}
                                                    <br />
                                                    <span className="text-muted small">
                                                        $/CT ${parseFloat(value.supplierRate)?.toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> : ""}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4 btn-square px-10"
                    >
                        cancel
                    </Button> : ""}
                    <Button className="btn-square px-10"
                        variant="primary"
                        onClick={()=> RemoveStonesConfirm(props)}
                    >
                        Yes
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
