import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { BASE_URL, NODE_API_URL } from "../../../../../env_config";
import axios from "axios";
import Cookies from "js-cookie";

export const Lab = ({userinfo}) => {
    const [LabData,setLabData] = useState([])
    useEffect(() => {
        axios({
            method: "post",
            url: `${NODE_API_URL}/SUPP/get-all-lab`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            },
            data: {
                "diamondType": userinfo.type === "Natural" ? "N":"L"
            }
        })
            .then(function (res) {
                if (res && res.data.success && res.data.data) {
                    const options = res.data.data.map((item,index) => ({
                        id: index + 1,
                        lab: item.Lab
                    }));
                    setLabData(options)
                }
            })
            .catch(function (res) {
                if (res?.response?.data?.message === "UnAuthorized!") {
                    var cookies = document.cookie.split(";");
                    for (var i = 0; i < cookies.length; i++) {
                        if (cookies[i] && !cookies[i].includes("view")) {
                            var equals = cookies[i].indexOf("=");
                            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                        }
                    }
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
    }, []);
    // const LabData = ([
    //     {
    //         id: 1,
    //         lab:"GIA"
    //     },
    //     {
    //         id: 2,
    //         lab:"IGI"
    //     },
    //     {
    //         id: 3,
    //         lab:"HRD"
    //     },
    //     {
    //         id: 4,
    //         lab:"OTHER"
    //     }
    // ]);
    // const LabData_lab = ([
    //     {
    //         id: 1,
    //         lab:"GIA"
    //     },
    //     {
    //         id: 2,
    //         lab:"IGI"
    //     },
    //     {
    //         id: 3,
    //         lab:"HRD"
    //     },
    //     {
    //         id: 4,
    //         lab:"GCAL"
    //     },
    //     {
    //         id: 5,
    //         lab:"OTHER"
    //     }
    // ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">LAB</p>
                </div>
                {userinfo.type === "Natural" ? 
                    <div className="col-lg-10">
                        {LabData.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                                <div className="labBox selectBox">
                                    <span>{item.lab}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                :
                    <div className="col-lg-10">
                        {LabData.map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                                <div className="labBox selectBox">
                                    <span>{item.lab}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                }
            </div>
        </>
    );
};