/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React,{useEffect,useState,Suspense} from "react";
import { Redirect, Switch, Route} from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import {ErrorPage} from "./modules/ErrorsExamples/ErrorPage";
// import { GetCookies } from "../_metronic/_helpers/Cookies";
import axios from "axios"
import { GetCookies,RemoveCookies } from "../_metronic/_helpers/Cookies";
import { NODE_API_URL} from "./../env_config"
import ProfileSetup from "../_metronic/_partials/profileSetup/ProfileSetup";
import { BASE_URL } from "../env_config";

export function Routes() {
  
  const [userinfo,setUserInfo] = useState([])
  const [subSuppData,setSubSuppData] = useState([])
  const [subId,setSubId] = useState()
  const [companyRef,setCompanyRef] = useState([])
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  useEffect(() => {
 
    if (isAuthorized){
      GetCookies("resData").then(response => {
        if(response){
              let userobj = JSON.parse(response)
              axios({
                method: "GET",
                url: NODE_API_URL + "/SUPP/profile-data", 
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                data: {"sub_supp_id": userobj.sub_supp_id ? userobj.sub_supp_id : ""},
              }).then((res) => {
                if (res && res.data ) {
                    setUserInfo(res.data.data[0])
                    if(res.data.data.onOff === 0){
                      window.location.replace(`${BASE_URL}/logout`);
                    }
                    if(res.data.data.sub_supp_data){
                      setSubSuppData(res.data.data.sub_supp_data)
                      if(res.data.data.sub_supp_data.login_onOff == 0 || res.data.data.sub_supp_data.isDelete == 1 ){
                        window.location.replace(`${BASE_URL}/logout`);
                      }
                    }
                    setCompanyRef(res.data.company_ref)
                }
              }).catch((res) => {
                if(res?.response?.data?.message === "UnAuthorized!"){
                  localStorage.clear();
                  window.location.replace(`${BASE_URL}/logout`);
                }
              });

              axios({
                method: "PATCH",
                url: NODE_API_URL + "/SUPP/first-timezone",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                data: {
                  "id":userobj.id,
                  "timezone":Intl.DateTimeFormat().resolvedOptions().timeZone
                },
              }).catch((res) => {
                if(res?.response?.data?.message === "UnAuthorized!"){
                  localStorage.clear();
                  window.location.replace(`${BASE_URL}/logout`);
                }
              });

            setSubId(userobj.sub_supp_id ? userobj.sub_supp_id : null)
        }
        else{
          RemoveCookies("resData")
          localStorage.clear()
          const hreff = window.location.href
          const hreffsegment = hreff.split("/")[2]
          if (hreffsegment === "localhost:3000"){
            window.location.replace(`${BASE_URL}/logout`);
          } else {
            window.location.replace(`${BASE_URL}/logout`);
          }
         
       }
       
      })

      // GetCookies("_subId").then(response => {
      //   if(response){
      //     setSubId(response ? response : null)
      //   }else{
      //     setSubId(null)
      //   }
      // })
      return 
    }
     
  
  },[isAuthorized])
  useEffect(() => {
    // console.log(window.location.pathname,"window.location.href")
    if (window.location.pathname === '/search-result' || window.location.pathname === '/search-result/stock-search') {
      // setDisableDropdown(true)
    }
    else {
      sessionStorage.removeItem("pageReloaded")
    }
  }, [window.location.href])

 
  // if(!user){
  //   return
  // }

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
       <>
         {userinfo.length !== 0 ?
          <>
            {userinfo.type ? 
              <Layout userinfo={userinfo} subId={subId} subSuppData={subSuppData}>
                <BasePage companyRef={companyRef} userinfo={userinfo} subId={subId}/>
              </Layout> 
            :
              <>
                <Suspense fallback={<LayoutSplashScreen />}>
                  <Switch>
                      {
                          /* Redirect from root URL to /dashboard. */
                          <Redirect exact from="/" to="/profilesetup" />
                      }
                      <ContentRoute path="/profilesetup" component={() => <ProfileSetup userinfo={userinfo} subId={subId} isAuthorized={isAuthorized}/>} />
                  </Switch>
              </Suspense>
              </>
            }
          </>
          : ""} 
       </>
      )}
    </Switch>
  );
}
