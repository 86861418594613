import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../_helpers";
// import SVG from "react-inlinesvg";

function numberWithCommas(x) {
    if (x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "0.00";
    }
}

function reasonGroup (x) {
    var y = x.replace(/,\s*$/, "");
    var reason = y.split(',').map(function (x, index) {
        return <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold mr-1 mb-1 text-nowrap" key={index}>{ x }</span>; 
    });

    return <div className="d-flex align-items-center justify-content-center flex-wrap">{reason}</div>;
}

export const InvalidDiamondColumn = () => [
   
    {
        Header: "Reason",
        accessor: "reason",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{ row.value ? reasonGroup(row.value): "-"}</>;
        },
    },
    {
        Header: "Country",
        accessor: "country",
        className: "text-center align-middle",
    },
    {
        Header: "Stone ID",
        accessor: "loatNO",
        className: "text-center text-nowrap align-middle",
    },
    {
        Header: "Diamond Type",
        accessor: "diamondType",
        className: "text-center text-nowrap align-middle",
    },
    {
        Header: "Carat",
        accessor: "cWeight",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{parseFloat(row.value).toFixed(2)}</>;
        },
        sortType: (rowA, rowB) => {
            if (rowB.original.C_Weight > rowA.original.C_Weight) return 1;
            if (rowA.original.C_Weight > rowB.original.C_Weight) return -1;
        }
    },
    {
        Header: "Shape",
        accessor: "cShape",
        className: "text-center align-middle p-2",
    },
    {
        Header: "Color",
        accessor: (d) => (
            <>
                {
                    d.cColor === "fancy" ?
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    { d.cColor === "fancy" ? (
                                        <>
                                            {d.fIntensity}{" "}{d.fOvertone}{" "}{d.fColor}
                                        </>
                                    ) : (
                                        d.cColor
                                    )}
                                </Tooltip>
                            }
                        >
                            <span className="d-inline-block text-truncate text-dark" style={{ maxWidth: "80px" }}>
                                {d.cColor === "fancy" ? (
                                    <>
                                        {d.fIntensity}{" "}{d.fOvertone}{" "}{d.fColor}{" "}
                                    </>
                                ) : (
                                    d.cColor
                                )}
                            </span>
                        </OverlayTrigger>
                    : d.cColor
                }
            </>
        ),
        minwidth: 50,
        className: "text-center align-middle",
    },
    {
        Header: "Clarity",
        accessor: "cClarity",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Cut",
        accessor: "cCut",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Polish",
        accessor: "cPolish",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Symmetry",
        accessor: "cSymmetry",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Fluo.",
        accessor: "cFluorescence",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Lab",
        accessor: "lab",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Certificate",
        accessor: "certiNO",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "$/CT",
        className: "text-right align-middle pr-5",
        accessor: "oRate",
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => (
            <>
                ${numberWithCommas(parseFloat(row.value))}
            </>
        ),
    },
    {
        Header: "Total",
        className: "text-right align-middle pr-5",
        accessor: (d) => {
            return (
                <>
                    ${numberWithCommas(parseFloat(d.oRate * d.cWeight)) }
                </>
            );
        },
        disableSortBy: true,
    }
];
