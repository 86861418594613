import React from "react";
import Footer from "./Footer"
import Contact from "./Homepage/Contact";

const Terms = () =>{
    return(
        <>
            <title>Terms and Conditions | Diamonds on Call Ltd - Suppliers Centre</title>
            <div class="container mt-5 pt-5">
                <h1 class="text-dark text-center">Terms &amp; Conditions</h1>
                <p>MOBILE SITE/APP TERMS &amp; CONDITIONS </p>
                <p>Supplier.DiamondsOnCall.com<br />
                    Last updated 01/08/2020 </p>
                <p><strong><em>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY</em></strong></p>
                <p><strong>AGREEMENT TO TERMS</strong><br />
                    These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an business entity (&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of the supplier.diamondsoncall.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the &ldquo;Site&rdquo;). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS and CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY. </p>
                <p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason. We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of these Terms and Conditions and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms are posted. </p>
                <p>The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.&nbsp;</p>
                <p>The Site is intended for users who are at least 18 years old and are authorized representative to entity. Persons under the age of 18 are not permitted to register for the Site. </p>
                <p><strong>Proprietary Property </strong><br />
                    Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the &ldquo;Content&rdquo;) and logos contained therein are owned or controlled by us. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>
                <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access. We reserve all rights not expressly granted to you in and to the Site, and Content.</p>
                <p><strong>USER REPRESENTATIONS</strong><br />
                    By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete;<strong> </strong>(2) you will maintain the accuracy of such information and promptly update such registration information as necessary;<strong> </strong>(3) you have the legal capacity and you agree to comply with these Terms of Use; (4) you are not under the age of 18;] (5) not a minor in the jurisdiction in which you reside; (6) you will not access the Site through automated or non-human means, whether through a bot, script or otherwise; (7) you will not use the Site for any illegal or unauthorized purpose; and (8) your use of the Site will not violate any applicable law or regulation.</p>
                <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof). </p>
                <p><strong>USER REGISTRATION</strong><br />
                    You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.<br />
                    &nbsp;<br />
                    <strong>TERMS OF USE</strong><br />
                    By using the Site, you oblige by following terms of use<br />
                    1) HOLD/SALE </p>
                <ul>
                    <li>Once you Approve Order Request on the site, you are bind to Sale unless, stated by us during the process of Sale.</li>
                    <li>Once Hold request approved, if all necessary properties of diamond are not mentioned and agreed such as Shade, Eye clean, BGM the Order can be rejected on the basis of misrepresentation of data.</li>
                    <li>Even though order was approved and confirmed, Diamonds on call reserves the rights to reject that order for any reason.</li>
                    <li>All Orders Approved and confirmed are purchase on outright basis. Suppliers are not allowed to resale such diamonds in any circumstances.</li>
                </ul>
                <p>2) CONSIGNMENT ORDERS &amp; RETURNS</p>
                <ul>
                    <li>We may request any diamond/order on consignment, until the confirmed order request is approved. <br />
                    </li>
                </ul>
                <p>3) IMPORT &amp; LOCAL TAXES</p>
                <ul>
                    <li>All the orders processed are subject to Export &amp; Local Tax of the Country of Supplier or Consigner. All the tax should be borne by the entity itself. Diamonds on call Limited will not be liable or responsible for any such duty or penalty thereafter.<br />
                    </li>
                </ul>
                <p>4)  KYC DOCUMENTATION</p>
                <ul>
                    <li>When necessary, weather before or after the account activation, Diamonds on call preserves rights to ask for full KYC Documentation or update for it. Non-Delivery of such documentations in given time, Diamonds on call preserves right to temporary suspend the account for any future transactions. </li>
                </ul>
                <p> 5)  PRICING</p>
                <ul>
                    <li>Pricing of the stone uploaded, and order processed are assumed to be the price for the Diamond. Any changes after that are not to be considered.</li>
                    <li>The company reserves its right to correct/rectify any error including an error pertaining to the price of a stone for which an order has been placed, if it is found that the error is a result of system/data entry/technology or description of the stone is inaccurate that resulted in wrong price display.</li>
                    <li>All the Pricing Mentioned is in US Dollars. For Local Currency please contact your dedicated Purchase Executive.</li>
                </ul>
                <p>6) TERMS OF ORDER</p>
                <ul>
                    <li>Payment: Once every Order approved and confirmed, Payment will be made in 14 days of delivery and invoicing. </li>
                    <li>Once Order approved and confirmed, Diamond should be delivered to Diamonds On Call Limited within 3 working days. If the ordered Diamond is not received in time, the order will be canceled and account will be suspended.</li>
                    <li>Wire or Transaction fee of the Consigner bank and all inter-mediatory banks will be borne by consigner. Diamonds On Call Limited will only borne the local bank charges.</li>
                    <li>When necessary Diamonds On Call Preserves all rights request for Diamond before approval of the order.</li>
                </ul>
                <p>&nbsp;</p>
                <p>7) SHIPMENTS</p>
                <ul>
                    <li>The stones will be shipped to be delivered at our doorstep through couriers such as Brinks, Malca-Amit, FedEx, UPS, any other shipping company or personally.</li>
                    <li>Shipping should include door to door insurance by consigner.</li>
                    <li>Diamonds On Call Limited reserves the right to refuse shipments under conflict.</li>
                    <li>Diamonds On Call Limited will take deliveries only in HONG KONG, and will not bear any shipping charges for shipping value above 15,000 USD unless agreed before in writing.</li>
                </ul>
                <p>8.)  CANCELLATION OF ORDER</p>
                <ul>
                    <li>Once Order approved and confirmed the Supplier is bind to honor the order. Order cannot be canceled once approved and confirmed.</li>
                </ul>
                <p>&nbsp;</p>
                <p><br />
                    <br />
                <strong>PROHIBITED ACTIVITIES</strong><br />
                    You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. </p>
                <p>As a user of the Site, you agree not to:</p>
                <ul>
                    <li>Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                    <li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                    <li>Use a buying agent or purchasing agent to make purchases on the Site.</li>
                    <li>Use the Site to advertise or offer to sell goods and services.</li>
                    <li>Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.</li>
                    <li>Engage in unauthorized framing of or linking to the Site.</li>
                    <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                    <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                    <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                    <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</li>
                    <li>Attempt to impersonate another user or person or use the username of another user.</li>
                    <li>Sell or otherwise transfer your profile.</li>
                    <li>Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
                    <li>Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
                    <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.</li>
                    <li>Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.</li>
                    <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.</li>
                    <li>Copy or adapt the Site&rsquo;s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                    <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.</li>
                    <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (&ldquo;gifs&rdquo;), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or &ldquo;pcms&rdquo;).</li>
                    <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.</li>
                    <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
                    <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>GUIDELINES FOR REVIEWS</strong><br />
                    We may provide you areas on the Site to leave reviews or ratings. When posting a review, you must comply with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed; (2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hate language; (3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability; (4) your reviews should not contain references to illegal activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you may not post any false or misleading statements; and (8) you may not organize a campaign encouraging others to post reviews, whether positive or negative. </p>
                <p>We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sub-licensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.<br />
                    <strong>&nbsp;</strong><br />
                    <strong>MOBILE APPLICATION LICENSE</strong><br />
                    Use License<br />
                    If you access the Site via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Terms of Use. You shall not: (1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application; (3) violate any applicable laws, rules, or regulations in connection with your access or use of the application; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application; (5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended; (6) make the application available over a network or other environment permitting access or use by multiple devices or users at the same time; (7) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application; (8) use the application to send automated queries to any website or to send any unsolicited commercial e-mail; or (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.</p>
                <p><strong>Apple and Android Devices</strong><br />
                    The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play (each an &ldquo;App Distributor&rdquo;) to access the Site: (1) the license granted to you for our mobile application is limited to a non-transferable license to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor&rsquo;s terms of service; (2) we are responsible for providing any maintenance and support services with respect to the mobile application as specified in the terms and conditions of this mobile application license contained in these Terms of Use or as otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the mobile application; (3) you represent and warrant that (i) you are not located in a country that is subject to a Hong Kong and U.S. government embargo, or that has been designated by the Hong Kong and U.S. government as a &ldquo;terrorist supporting&rdquo; country and (ii) you are not listed on any Hong Kong and U.S. government list of prohibited or restricted parties; (4) you must comply with applicable third-party terms of agreement when using the mobile application, e.g., if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the mobile application; and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and conditions in this mobile application license contained in these Terms of Use, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile application license contained in these Terms of Use against you as a third-party beneficiary thereof. <br />
                    <br />
                    <strong>SUBMISSIONS</strong><br />
                    You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site (&quot;Submissions&quot;) provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions. </p>
                <p><strong>THIRD PARTY WEBSITES AND CONTENT</strong><br />
                    The Site may contain (or you may be sent via the Site) links to other websites (&quot;Third-Party Websites&quot;) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (&quot;Third-Party Content&quot;). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms of Use no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Site or relating to any applications you use or install from the Site. You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services on third party website. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites. </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>SITE MANAGEMENT</strong><br />
                    We reserve the right, but not the obligation, to:&nbsp;(1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in&nbsp;our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</p>
                <p><strong>PRIVACY POLICY</strong><br />
                    We care about data privacy and security. Please review our Privacy Policy <strong>CLICK HERE.</strong> By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised the Site is hosted in the Hong Kong. If you access the Site from the European Union, United States, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the Hong Kong, then through your continued use of the Site or Services, you are transferring your data to the Hong Kong, and you expressly consent to have your data transferred to and processed in the Hong Kong. <br />
                    <br />
                    <strong>TERM AND TERMINATION</strong><br />
                    These Terms of Use shall remain in full force and effect while you use the Site. Without limiting any other provision of these terms of use, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the site (including blocking certain ip addresses), to any person for any reason or for no reason, including without limitation for breach of any representation, warranty, or covenant contained in these terms of use or of any applicable law or regulation. We may terminate your use or participation in the site or delete your account and any content or information that you provided at any time, without warning, in our sole discretion. </p>
                <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                <p><strong>MODIFICATIONS AND INTERRUPTIONS </strong><br />
                    We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site. </p>
                <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.</p>
                <p><strong>GOVERNING LAW </strong><br />
                    These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of Hong Kong,<strong> </strong>applicable to agreements made and to be entirely performed within Hong Kong, without regard to its conflict of law principles. </p>
                <p><strong>DISPUTE RESOLUTION</strong><br />
                    Any legal action of whatever nature brought by either you or us (collectively, the &ldquo;Parties&rdquo; and individually, a &ldquo;Party&rdquo;) shall be commenced or prosecuted in the courts located in HONG KONG County, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non convenience with respect to venue and jurisdiction in such courts. In no event shall any claim, action, or proceeding brought by either Party related in any way to the Site be commenced more than Three months after the cause of action arose.</p>
                <p><strong>CORRECTIONS</strong><br />
                    There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Site, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.</p>
                <p><strong>DISCLAIMER</strong><br />
                    The site is provided on an as-is and as-available basis. You agree that your use of the site services will be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the site and your use thereof, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties or representations about the accuracy or completeness of the site&rsquo;s content or the content of any websites linked to this site and we will assume no liability or responsibility for any (1) errors, mistakes, or inaccuracies of content and materials, (2) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the site, (3) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, (4) any interruption or cessation of transmission to or from the site, (5) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the site by any third party, and/or (6) any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the site. We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the site, any hyperlinked website, or any website or mobile application featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and any third-party providers of products or services. As with the purchase of a product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.</p>
                <p><strong>LIMITATIONS OF LIABILITY</strong><br />
                    In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the site, even if we have been advised of the possibility of such damages. <br />
                    <br />
                    <strong>USER DATA</strong><br />
                    We will maintain certain data that you transmit to the Site for the purpose of managing the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.<br />
                    <strong></strong> <br />
                    <strong>ELECTRONIC COMMUNICATIONS AND TRANSACTIONS </strong><br />
                    Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means. </p>
                <p><strong>MISCELLANEOUS</strong><br />
                    These Terms of Use and any policies or operating rules posted by us on the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</p>
                <p><strong>CONTACT US&nbsp;</strong><br />
                    In order to resolve a complaint regarding the Site or to receive further information regarding use of the App/Site, please contact us at:<strong> </strong></p>
                <p>DIAMONDS ON CALL LIMITED<br />
                    UNIT E-F, 8/F, 8 Hart Avenue,<br />
                    Tsim Sha Tsui, Kowloon, Hong Kong <br />
                    +852 3565 5670<br />
                    supplier@diamondsoncall.com</p>
            </div>
            <Contact />
            <Footer />
        </>
    )
} 
export default Terms;