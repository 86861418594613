import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const renderMilkyLabel = (milky) => {

    return (
        <span className="advTag ml-2 mb-2">
            {milky && milky.toLowerCase() === "no milky" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                NO MILKY
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {milky && milky.toLowerCase() === "luster low" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Luster Low
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {milky && milky.toLowerCase() === "light milky" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                LIGHT MILKY
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }

            {milky && milky.toLowerCase() === "milky" ?
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                MILKY
                            </Tooltip>
                        }
                    >
                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                    </OverlayTrigger>
                </span>
                :
                null
            }
        </span>
    );
};
export default renderMilkyLabel;