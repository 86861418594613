import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Brand } from "./Additional/Brand";
// import { KeytoSymbol } from "./Additional/KeytoSymbol";
import { Origin } from "./Additional/Origin";
import { Treatment } from "./Additional/Treatment";
import { GrowthType } from "./Additional/GrowthType";
import { KeytoSymbol } from "./Additional/KeytoSymbol";
// import { Type } from "./Additional/Type";

export const Additional = ({userinfo,formik}) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        ADDITIONAL DETAILS
                    </Accordion.Toggle>
                    {/* <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            {userinfo.type === "Natural" ? 
                                <>
                                    <Brand />
                                    <Origin />
    
                                </>                            :
                                <GrowthType />
                            }
                            <Treatment userinfo={userinfo} />
                        </Card.Body>
                    </Accordion.Collapse> */}
                    {userinfo?.type === "Natural" ? <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Brand/>
                            <Origin/>
                            <Treatment  userinfo={userinfo}/>
                            {/* <Type /> */}
                            <KeytoSymbol/>
                        </Card.Body>
                    </Accordion.Collapse>:<Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <GrowthType/>
                            <Treatment formik={formik}  userinfo={userinfo}/>
                            <KeytoSymbol/>
                        </Card.Body>
                    </Accordion.Collapse>}
                </Card>
            </Accordion>
        </>
    );
};
