import React, { useMemo, useState } from 'react';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../_helpers";
import { FaExternalLinkAlt} from "react-icons/fa";
import {Modal,Button} from "react-bootstrap";
import VideoIframe from "../widgets/tiles/VideoIframe";
import { MainImagePopup } from '../widgets/tiles/MainImagePopUp';
import { round } from 'lodash';
import SVG from 'react-inlinesvg';


export default function  SearchExpandedDetais({row,userinfo}){
    const userData = useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
// console.log(userinfo.type)
    const [showVideo, setShowVideo] = useState(false);
    const [imageBroken, setImageBroken] = useState(false);
    const [imageBrokenAgain, setImageBrokenAgain] = useState(false);
    const [showAssetImg, setShowAssetImg] = useState(false);
    const [showArrowImg, setShowArrowImg] = useState(false);
    const [showHeartImg, setShowHeartImg] = useState(false);
    const [heartBroken, setHeartBroken] = useState(false);
    const [arrowBroken, setArrowBroken] = useState(false);
    const [assetBroken, setAssetBroken] = useState(false);
    const GenerateTitle = (row) => {
        let title = ""
     
        if(row?.shape){
            title += " "
            title += row?.shape
        }
        if(row?.carat){
            title += " "
            title += row?.carat
        }
       
        if(row?.color){
            title += " "
            title += row?.color
        }
        if(row?.clarity){
            title += " "
            title += row?.clarity
        }

        if(row?.cut){
            title += " "
            title += row?.cut
        }
       
        if(row?.polish){
            title += " "
            title += row?.polish
        }

        if(row?.symmetry){
            title += " "
            title += row?.symmetry
        }

        if(row?.fluorescence){
            title += " "
            title += row?.fluorescence
        }
        return title
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openVideoModal() {
        setShowVideo(true)
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }


    const [mainimageclick,setMainImageClick] = useState(false)

    


    function discount(x){
        
        let orignal_price = parseFloat(x.oRate * x.carat).toFixed(2);
        let carat_price = parseFloat(orignal_price / x.carat).toFixed(2);
        let discount = (x.cRap||x.rapRate) ? parseFloat((carat_price - (x.cRap || x.rapRate)) / (x.cRap || x.rapRate) * 100).toFixed(2) : "0.00" 
        return parseFloat(discount).toFixed(2);
    }

    return(
       <>
        <div className={`expandedDetails ${row?.isfancy || row?.supplierComment ? "fancyExpand" : "" }`} >
            <div className="row pt-2 m-0">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        <li className="img_thumb">
                            {row?.image && row?.image != "null" ? (
                                <div>
                                <MainImagePopup Image={row?.image} Shape={row?.shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                { imageBroken ? 
                                    <>
                                        {imageBrokenAgain ?  
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                onError={() => setImageBrokenAgain(true)}
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${row?.shape.toLowerCase()}-no.png`
                                                )}
                                            /> 
                                        : 
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/noimage.jpg`
                                                )}
                                            /> 
                                        }
                                    </>
                                :
                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row?.shape}
                                        src={row?.image}
                                        onClick={() => setMainImageClick(true)}
                                        onError={() => setImageBroken(true)}

                                    />
                                }
                                </div>
                            ) : (
                                <div>
                                    <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row?.shape.toLowerCase()}-no.png` )} Shape={row?.shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                <img
                                    className="img thumb"
                                    alt="shape"
                                    src={toAbsoluteUrl( `/media/shape/${row?.shape.toLowerCase()}-no.png` )}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/noimage.jpg`
                                        )
                                    }}
                                />
                                </div>
                            )}
                        </li>
                        {row?.video  ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/videoIcon.svg")} />
                            </li>
                        ):('')}
                        {row?.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/heartImage.svg")} />
                            </li>
                            
                        ):('')}
                        {row?.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/arrowImage.svg")} />
                            </li>
                        ):('')}
                        {row?.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/assetIcon.svg")} />
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4">
                        {row?.image && row?.image != "null" ? (
                            <div>
                                <MainImagePopup Image={row?.image} Shape={row?.shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                { imageBroken ? 
                                    <>
                                        {imageBrokenAgain ?  
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                onError={() => setImageBrokenAgain(true)}
        
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${row?.shape.toLowerCase()}-no.png`
                                                )}
                                            /> 
                                        : 
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/noimage1.jpg`
                                                )}
                                            /> 
                                        }
                                    </>
                                :
                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row?.shape}
                                        src={row?.image}
                                        onClick={() => setMainImageClick(true)}
                                        onError={() => setImageBroken(true)}

                                    />
                                }
                            </div>
                        ) : (
                            <div>
                                <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row?.shape.toLowerCase()}-no.png` )} Shape={row?.shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                            <img
                                className="img-fluid"
                                alt="shape"
                                src={toAbsoluteUrl(
                                    `/media/shape/${row?.shape.toLowerCase()}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                            />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            <div className="mr-2"> 
                                {row?.location ? (
                                    <OverlayTrigger
                                        placement="top"
                                        className="text-capitalize"
                                        overlay={<Tooltip id="tooltip">{row?.location}</Tooltip>}
                                    >
                                        <img
                                            className="img-fluid flagIco"
                                            alt={row?.location}
                                            src={toAbsoluteUrl(`/media/map/${row?.location.toLowerCase()}.png`)}
                                        />
                                    </OverlayTrigger>
                                ) : (
                                    null
                                )}
                            </div >
                            
                            <div className="mr-2 border-right text-primary">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={row?.certificate_link}
                                >
                                    {row?.lab} : {row?.certificate} <FaExternalLinkAlt />
                                </a>
                            </div>
                            <div >Stone ID: <div className="text-primary d-inline-block mr-2">{row?.loatNo}</div></div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h4 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        <div className="col-12 mt-5">
                            <span className="text-muted font-weight-bold font-size-sm">$/CT : ${parseFloat(row?.supplierRate).toFixed(2)}</span>
                            <div className='d-flex align-items-center' ><span className="font-size-h2 font-weight-bold mb-0 d-block">${parseFloat(row?.supplierPrice).toFixed(2) }</span> <span className={`${row?.supplierDiscount >= 0  ? 'text-success' : 'text-danger'} pl-2`}> {row?.supplierDiscount}</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row?.isfancy) ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row?.fancy_intensity ? row?.fancy_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row?.fancy_overtone ? row?.fancy_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row?.fancy_color ? row?.fancy_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Parameter Details </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">Measurement</div>
                                <div className="col-6 font-weight-bold"> {row?.length ? parseFloat(row?.length).toFixed(2) : "0.00"} * {row?.width ? parseFloat(row?.width).toFixed(2) : "0.00"} * {row?.height ? parseFloat(row?.height).toFixed(2) : "0.00"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 ">Table (%) </div>
                                <div className="col-6 font-weight-bold"> {row?.tablePercent ? parseFloat(row?.tablePercent).toFixed(2) : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Depth (%)</div>
                                <div className="col-6 font-weight-bold"> {row?.depthPercent ? parseFloat(row?.depthPercent).toFixed(2) : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">CR.Height</div>
                                <div className="col-6 font-weight-bold">{row?.crownHeight ? row?.crownHeight : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">CR.Angle</div>
                                <div className="col-6 font-weight-bold">{row?.crownAngle ? row?.crownAngle : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">PAV.Height</div>
                                <div className="col-6 font-weight-bold">{row?.pavilionHeight ? row?.pavilionHeight : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">PAV.Angle</div>
                                <div className="col-6 font-weight-bold">{row?.pavilionAngle ? row?.pavilionAngle : "-"}</div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">Ratio</div>
                                <div className="col-6 font-weight-bold"> {row?.Ratio || "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Girdle %</div>
                                <div className="col-6 font-weight-bold">{row?.girdle ? row?.girdle : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Girdle Condition</div>
                                <div className="col-6 font-weight-bold">{row?.girdleCondition  ? row?.girdleCondition : "-"}</div>
                            </div>
                        </div>
                        <div className="col-6 border-left">
                            <h5 className="font-size-h5 text-dark font-weight-bolder mb-3  mt-5">Additional Details</h5>
                            <div className="row mb-1">
                                <div className="col-6">City</div>
                                <div className="col-6 font-weight-bold">{row?.city ? row?.city : "-"}</div>
                            </div>
                            { userinfo.type != "Natural" ? "" :
                                <div className="row mb-1">
                                    <div className="col-6">Brand</div>
                                    <div className="col-6 font-weight-bold">{row?.brand ? row?.brand : "-"}</div>
                                </div>
                            }
                            <div className="row mb-1">
                                <div className="col-6">Shade</div>
                                <div className="col-6 font-weight-bold">{row?.shade  ? row?.shade : "-"}</div>
                            </div>
                            <hr className="w-75 ml-0"/>
                            <div className="row mb-1">
                                <div className="col-6">Milky</div>
                                <div className="col-6 font-weight-bold">{row?.milky  ? row?.milky : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Eye Clean</div>
                                <div className="col-6 font-weight-bold">{row?.eyeClean ? row?.eyeClean : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Culet</div>
                                <div className="col-6 font-weight-bold"> {row?.cutlet  ? row?.cutlet : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Key to Symbol</div>
                                <div className="col-6 font-weight-bold">{row?.keyToSymbol ? row?.keyToSymbol : "-" }</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Availability </div>
                                <div className="col-6 font-weight-bold"> {row?.availability ? row?.availability : "-"}</div>
                            </div>
                            {userinfo.type != "Natural" ? 
                                <>
                                    <div className="row mb-1">
                                        <div className="col-6"> Growth Type </div>
                                        <div className="col-6 font-weight-bold">  {row?.growthType ? row?.growthType : "-"} </div>
                                    </div>
                                    {row?.green ?
                                        <div className="row mb-1">
                                            <div className="col-6"> Treatment </div>
                                            <div className="col-6 font-weight-bold">  {row?.treatment ? row?.treatment : "-"} </div>
                                        </div>
                                    : "" }
                                </>
                            :   
                                <>
                                    <div className="row mb-1">
                                        <div className="col-6"> Origin </div>
                                        <div className="col-6 font-weight-bold">  {row?.origin ? row?.origin : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6"> Treatment </div>
                                        <div className="col-6 font-weight-bold">  {row?.green ? row?.green : "-"} </div>
                                    </div>
                                </>
                            }
                            { row?.supplierComment ?
                                <div className="row mx-0 mt-5">
                                    <div className="col-12 pt-3 px-0 border-top">
                                        <p className="font-size-h4 font-weight-bold text-primary mb-3">Supplier Comment</p>
                                        <div className="row mb-1">
                                    <div className="col-12">{row?.supplierComment}</div>
                                </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            show={showHeartImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                {heartBroken ?
                    <img  src={toAbsoluteUrl(`/media/shape/${row?.shape.toLowerCase()}-no.png`)} alt={row?.shape} className="img-fluid" />
                :
                    <img src={row?.heart} alt={row?.shape} onError={()=>setHeartBroken(true)} className="img-fluid" />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showArrowImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                {arrowBroken ?
                    <img src={toAbsoluteUrl(`/media/shape/${row?.shape.toLowerCase()}-no.png`)} alt={row?.shape} className="img-fluid" />
                :
                    <img src={row?.arrow} alt={row?.shape} onError={()=> setArrowBroken(true)} className="img-fluid" />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showAssetImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                {assetBroken ?
                    <img src={toAbsoluteUrl(`/media/shape/${row?.shape.toLowerCase()}-no.png`)} alt={row?.shape} className="img-fluid" />
                :
                    <img src={row?.asset} alt={row?.shape}  onError={()=> setAssetBroken(true)}  className="img-fluid" />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                Close
                </Button>
            </Modal.Footer>
        </Modal>
        <VideoIframe
            showVideo={showVideo}
            handleClose={handleClose}
            src={row?.video }
        />
      </>
    )
}