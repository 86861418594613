import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button,Alert } from "react-bootstrap";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../env_config';
import { toAbsoluteUrl } from "../../_helpers";
import AdvancedTag from "./AdvancedTag";
import renderShadeLabel from "./ShadeTag";
import renderMilkyLabel from "./MilkyTag";
import renderEyecleanLabel from "./EyecleanTag";
import renderGrowthTypeLabel from "./GrowthTypeCheck";
import Select from "react-select";
import { FaEdit, FaRegEdit, FaSave } from "react-icons/fa";
// import { toAbsoluteUrl } from "../../_helpers";
const customShadeStyles = (selectedOption) => ({
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        width: '200px', // Set the dropdown width to 200px
        minWidth: '200px', // Ensure the menu doesn't shrink below 200px
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    control: (provided) => ({
        ...provided,
        minHeight: '28px',
        height: '28px',
        width: '120px', // Set the control width to 200px
        borderRadius: '0.42rem', // Round corners for the "tag style"
        border: 'none',
        backgroundColor: '#f3f5f9', // Apply dynamic background color
        color: selectedOption ? selectedOption.color : '#333', // Dynamic text color
        padding:0,
        marginBottom:5,
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Align items horizontally (for tags)
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '10px',
        fontWeight:'bold',
        textAlign:'center',
        padding:0,
        color: selectedOption ? selectedOption.color : '#333', // Dynamic text color
    }),
   dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0 5px',
    }),
    indicatorSeparator: () => ({
        display: 'none', // Hide the separator line
    }),
    clearIndicator: (provided) => ({
        ...provided,
    }),
});


const initialValues = {
    Shade: false,
    ShadeComment:"",
    Milky: false,
    MilkyComment:"",
    EyeClean:false,
    EyeCleanComment:"",
    Open:true,
    OpenComment:"No Open",
    DisplayLuster:false,
    Luster:false,
    LusterComment:"",
    DisplayGrowthType:false,
    GrowthType:false,
    GrowthTypeComment:"",
    comments: ""
};

const CustomOption = (props) => {
    console.log(props)
    const { data, innerRef, innerProps } = props;
    return (
        <>
            {/* <div
                ref={innerRef}
                {...innerProps}
                style={{
                    ...props.getStyles('option', props),
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 10px',
                    backgroundColor: data.color || '#ddd', // Default background if no color is provided
                    color: '#fff',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    margin: '2px 0',
                }}
            >
                {data.label}
            </div> */}
            <div className="w-100 px-2 mb-1 advTag">
                <div ref={innerRef}
                    {...innerProps} style={{color:data.color}} className={`label label-light-primary label-light-shade_${data.label.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{data.label}</div>
            </div>
        </>
    );
};
export const HoldApprove = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errmessage,setErrorMessage] = useState()
    // console.log(props)
    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);
    const shadeOptions = [
        { label: "None", value: "none", color: "#e0e000" },
        { label: "Light Brown", value: "light brown", color: "#764a43" },
        { label: "Brown", value: "brown", color: "#764a43" },
        { label: "Light Green", value: "light green", color: "#35a55a" },
        { label: "Green", value: "green", color: "#35a55a" },
        { label: "Grey", value: "grey", color: "#969c9a" },
        { label: "Black", value: "black", color: "#000" },
        { label: "Pink", value: "pink", color: "#f5616e" },
        { label: "Blue", value: "blue", color: "#0037bf" },
        { label: "Mix Tinge", value: "mix tinge", color: "#0037bf" }
    ];
   

    const milkyOptions = [
        { label: "No Milky", value: "NO MILKY" , color: "#868686"},
        { label: "Light Milky", value: "LIGHT MILKY", color: "#484848" },
        { label: "Milky", value: "MILKY", color: "#3f3f3f" }
    ];
    const eyeCleanOptions = [
        { label: "Yes", value: "YES", color: "#8950FC" },
        { label: "No", value: "NO", color: "#F64E60" } // Represents cases where eye clean is not explicitly determined as "YES"
    ];
    const lusterOptions = [
        { label: "Excellent", value: "EX", color: "#868686" },
        { label: "Very Good", value: "VG", color: "#868686" },
        { label: "Light Milky", value: "LIGHT MILKY", color: "#484848" },
        { label: "Milky", value: "MILKY", color: "#3f3f3f" }
    ];
    const growthTypeOptions = [
        { label: "CVD", value: "CVD" , color: "#1BC5BD"},
        { label: "HPHT", value: "HPHT", color: "#F64E60" } // Represents cases where eye clean is not explicitly determined as "YES"
    ];
    const RegistrationSchema = Yup.object().shape({
        Shade: Yup.boolean(),
        ShadeComment: Yup.string().when("Shade", {
            is: false, // If Shade is false
            then: Yup.string().required("Please Provide Shade Comment"), // ShadeComment is required
            otherwise: Yup.string().notRequired(), // Otherwise, it's not required
        }),
        Milky: Yup.boolean(),
        MilkyComment: Yup.string().when("Milky", {
            is: false, // If Shade is false
            then: Yup.string().required("Please Provide Milky Comment"), // ShadeComment is required
            otherwise: Yup.string().notRequired(), // Otherwise, it's not required
        }),
        EyeClean: Yup.boolean(),
        EyeCleanComment: Yup.string().when("EyeClean", {
            is: false, // If Shade is false
            then: Yup.string().required("Please Provide EyeClean Comment"), // ShadeComment is required
            otherwise: Yup.string().notRequired(), // Otherwise, it's not required
        }),
        Open: Yup.boolean(),
        OpenComment: Yup.string().when("Open", {
            is: false, // If Shade is false
            then: Yup.string().required("Please Provide Open Comment"), // ShadeComment is required
            otherwise: Yup.string().notRequired(), // Otherwise, it's not required
        }),
        DisplayLuster: Yup.boolean(),
        Luster: Yup.boolean(),
        LusterComment: Yup.string().when(["DisplayLuster", "Luster"], {
            is: (DisplayLuster, Luster) => DisplayLuster === true && Luster === false,
            then: Yup.string().required("Please Provide Luster Comment"),
            otherwise: Yup.string().notRequired(),
          }),
        DisplayGrowthType: Yup.boolean(),
        GrowthType: Yup.boolean(),
        GrowthTypeComment: Yup.string().when(["DisplayGrowthType", "GrowthType"], {
            is: (DisplayGrowthType, GrowthType) => DisplayGrowthType === true && GrowthType === false,
            then: Yup.string().required("Please Provide GrowthType Comment"),
            otherwise: Yup.string().notRequired(),
        }),
        comments: Yup.string()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            let comment = ""
            if(formik.values.ShadeComment){
                comment += `Shade:${formik.values.ShadeComment?.toString()?.toUpperCase()} `
            }
            if(formik.values.MilkyComment){
                comment += `Milky:${formik.values.MilkyComment?.toString()?.toUpperCase()} `
            }
            if(formik.values.EyeCleanComment){
                comment += `EyeClean:${formik.values.EyeCleanComment?.toString()?.toUpperCase()} `
            }
            if(formik.values.OpenComment){
                comment += `Open Comment:${formik.values.OpenComment?.toString()?.toUpperCase()} `
            }
            if(formik.values.LusterComment){
                comment += `Luster:${formik.values.LusterComment?.toString()?.toUpperCase()} `
            }
            if(formik.values.GrowthTypeComment){
                comment += `GrowthType:${formik.values.GrowthTypeComment?.toString()?.toUpperCase()} `
            }
            if(formik.values.comments){
                comment += `Additional Comments:${formik.values.comments?.toString()?.toUpperCase()} `
            }
            const finalData = {
                "userid":props.data.clientId,
                "diamond_type": props.data.diamondType === "L" ? "L" : "N" ,
                "certino":props.data.certiNo,
                "statsusid":1,
                "is_hold":`${props.data.holdStatus}`,
                "hold_duration":props.data.holdTime,
                "comment":comment,
                "confirm_id":props.data.confirmGoodsId
            }
            // console.log(finalData)

            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/hold-approve-or-rej`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                  },
                data: finalData,
            }).then((res) => {
                disableLoading();
                setSubmitting(false);
                // console.log("res123",res)
                if (res && res.data) {
                //     // setData(res.data.data)
                // props.holdListData();
                    props.onHide();
                    props.setTemp(!props.temp)
                    formik.resetForm()
                    props.setSuccessMsg(`Your hold request approved for  stone id : ${props.data.loatNo}`)
                    props.setLoader(true)
                    // window.location.reload()
                    setErrorMessage()
                }
            }).catch(error => {
                setSubmitting(false);
                // console.log(error.response.data.error,"ERROR")
                disableLoading();
                setErrorMessage(error.response.data.error)
                if(error?.response?.data?.message === "UnAuthorized!"){
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                  }
            })
            
        },
    });
    const selectedShadeOption = shadeOptions.find(option => option.value === formik.values.ShadeComment);
    const selectedMilkyOption = milkyOptions.find(option => option.value === formik.values.MilkyComment);
    const selectedEyeCleanOption = eyeCleanOptions.find(option => option.value === formik.values.EyeCleanComment);
    const selectedLusterOption = lusterOptions.find(option => option.value === formik.values.LusterComment);
    const selectedGrowthTypeOption = growthTypeOptions.find(option => option.value === formik.values.GrowthTypeComment);
    useEffect(() => {
        if(props?.data){
            if(props?.data?.shade){
                formik.setFieldValue("Shade",true)
                formik.setFieldValue("ShadeComment",props?.data?.shade)
            }
            if(props?.data?.milky){
                formik.setFieldValue("Milky",true)
                formik.setFieldValue("MilkyComment",props?.data?.milky)
            }
            if(props?.data?.eyeC){
                formik.setFieldValue("EyeClean",true)
                formik.setFieldValue("EyeCleanComment",props?.data?.eyeC)
            }
            if(props?.data?.cFluorescence === "STG" || props?.data?.cFluorescence === "MED"){
                formik.setFieldValue("DisplayLuster",true)
                if(props?.data?.luster){
                    formik.setFieldValue("Luster",true)
                    formik.setFieldValue("LusterComment",props?.data?.luster)
                }
            }
            if(props?.data?.diamondType === "L"){
                formik.setFieldValue("DisplayGrowthType",true)
                if(props?.data?.growthType){
                    formik.setFieldValue("GrowthType",true)
                    formik.setFieldValue("GrowthTypeComment",props?.data?.growthType)
                }
            }
        }
    },[props.data])
    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
        formik.resetForm()
    };
    // console.log(props.data.createdDate,"props.data.createdDate")
    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form
                    id=""
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    <Modal.Header>
                        <Modal.Title className="">
                            <span className="font-weight-bolder text-warning">
                                Order ID :{props.data.confirmGoodsId}
                            </span>
                        </Modal.Title>
                        {props.data?<span className="font-weight-bolder text-primary text-right">
                            {props.data.isHold?
                            <span className="label label-light-warning label-pill label-inline font-weight-bold text-nowrap">Hold Request</span>:
                            <span className="label label-light-success label-pill label-inline font-weight-bold text-nowrap">Buy Request</span>}
                        </span>:""}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {/* <div className="col-12">
                                Please accept all conditions or mention the reason in comment box to approve
                                {errmessage ? <Alert variant="danger"> {errmessage} </Alert>:""}
                            </div> */}
                            <div className="col-12 mt-5">
                                <div className="row">
                                    {props?.data?<div className="col-12 border-bottom">
                                    <div className="table-responsive custom-scrollbar w-100 mt-4">
                                    <table className="table" >
                                    <thead className="thead-light">
                                    <tr>
                                        <th  className="text-center text-nowrap align-middle">
                                                Diamond Detail
                                        </th> 
                                        <th  className="text-center text-nowrap align-middle">
                                                Advanced tags
                                        </th> 
                                        <th  className="text-center text-nowrap align-middle">
                                                Price
                                        </th>    
                                        <th  className="text-center text-nowrap align-middle">
                                                Date & Time
                                        </th>                  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr >
                                    <td className="text-center align-middle">
                                    {props.data.diamondType === "PN" || props.data.diamondType === "PL" ? 
                                    <div className="row">
                                        <div className="col-2 text-center p-0">
                                            <img
                                                className="img-fluid mr-2"
                                                alt={props.data.cShape}
                                                src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${props.data.cShape.toLowerCase()}.svg`)}
                                                width="50px"
                                                onError={e => {
                                                    e.currentTarget.src = toAbsoluteUrl(
                                                        `/media/shape/svg_shape/parcel/other.svg`
                                                        )
                                                    }}
                                            />
                                        </div>
                                        <div className="col-10 pl-2 p-0 text-left">
                                            <div className={`mb-1 ${props.data.diamondType === "PN" ? "natTab" : "labTab"}`}>
                                                <span className={`${props.data.diamondType}_diamond mr-2 small`}>
                                                    {props.data.diamondType}
                                                </span>
                                                <span className={`text-primary small ml-3`}>
                                                    {props.data.diamondType}-{props.data.parcelId}-{props.data.splitId}{props.data.cgSplitId ?<>/{props.data.cgSplitId}</> : ""}
                                                </span>
                                                <span className={`text-primary text-capitalize small ml-3`}>
                                                    {props.data.lengthMm} - {props.data.widthMm} MM
                                                </span>
                                            </div>

                                            <span className="text-dark font-weight-bold d-block">
                                                {props.data.cShape}{" "}
                                                {(props.data.cWeight)?.toFixed(2)}Ct.{" "}
                                                {props.data.cColor}{" "}
                                                {props.data.cClarity}{" "}{props.data.cCut}{" "}{props.data.cPolish}{" "}{props.data.cSymmetry}{" "}
                                                {props.data.cFluorescence}
                                            </span>
                                        </div>
                                    </div>
                                :
                                    <div className="row">
                                        <div className="col-2 text-center p-0">
                                            {props.data.image &&  props.data.image !=="null" ? (
                                                <img
                                                    className="img-fluid mr-2"
                                                    alt={props.data.cShape}
                                                    src={props.data.image}
                                                    width="50px"
                                                    onError={toAbsoluteUrl(
                                                        `/media/shape/${props.data.cShape ? props.data.cShape.toLowerCase() : ""}-no.png`
                                                    )}
                                                />
                                            ) : (
                                                <img
                                                    className="img-fluid mr-2"
                                                    alt={props.data.cShape}
                                                    src={toAbsoluteUrl(
                                                        `/media/shape/${props.data.cShape ? props.data.cShape.toLowerCase() : ""}-no.png`
                                                    )}
                                                    onError={e => {
                                                        e.currentTarget.src = toAbsoluteUrl(
                                                            `/media/shape/noimage.jpg`
                                                        )
                                                    }}
                                                    width="50px"
                                                    style={{ backgroundColor: "#f4f4f4" }}
                                                />
                                            )}
                                        </div>
                                        <div className="col-10 pl-2 p-0 text-left">
                                            <div className={`mb-1 ${props.data.diamondType === "W" || props.data.diamondType === "N" ? "natTab" : "labTab"}`}>
                                                <span className={`${ props.data.diamondType === "W" || props.data.diamondType === "N" ? props.data.C_Color === "fancy" ? "F" : "W" : props.data.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                                    {props.data.diamondType === "W" || props.data.diamondType === "N" ? props.data.C_Color === "fancy"  ? "NF" : "N" : props.data.C_Color === "fancy" ? "LF" : "L"}
                                                </span>
                                                <span  className={`text-primary small ml-3`}>
                                                    {props.data.loatNO}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary mx-2 small"
                                                    href={
                                                        props.data.certificate_link
                                                    }
                                                >
                                                    {props.data.lab}-{props.data.certiNo}
                                                </a>
                                            </div>

                                            <span className="text-dark font-weight-bold d-block">
                                                {props.data.cShape}{" "}
                                                {(props.data.cWeight)?.toFixed(2)}Ct.{" "}
                                                {props.data.cColor === "fancy" ? (
                                                    <>
                                                        {props.data.fIntensity}{" "}{props.data.fOvertone}{" "}{props.data.fColor}{" "}
                                                    </>
                                                ) : (
                                                    <>{props.data.cColor}{" "}</>
                                                )}
                                                {props.data.cClarity}{" "}{props.data.cCut}{" "}{props.data.cPolish}{" "}{props.data.cSymmetry}{" "}
                                                {props.data.cFluorescence}
                                            </span>
                                        </div>
                                    </div> 
                                    }    
                                    </td>  
                                    <td className="text-center align-middle">
                                        <AdvancedTag {...props.data}/>
                                    </td>
                                    <td className="text-center align-middle">
                                    <span className="font-weight-bolder text-primary">
                                        ${parseFloat(props.data.oRate * props.data.cWeight)?.toFixed(2)}
                                    </span>{" "}
                                    <br />
                                    <span className="text-muted small">
                                        $/CT ${parseFloat(props.data.oRate)?.toFixed(2)}
                                    </span>
                                    </td>
                                    <td className="text-center align-middle">
                                        {props.data.createdDate}
                                    </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                    </div>  
                                    </div>:""}
                                    <div className="col-12 mt-5 pl-md-15">
                                    <div className="form-group mb-1">
                                            <div className="row align-items-center">
                                                {/* Checkbox on the left */}
                                                {/* <label className="checkbox col-md-3 d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="pr-3"
                                                        name="Shade"
                                                        checked={formik.values?.Shade}
                                                        disabled= {!props?.data?.shade}
                                                        // onClick={() => {
                                                        //     if (formik.values?.Shade) {
                                                        //         formik.setFieldValue("ShadeComment", "");
                                                        //     } else {
                                                        //         formik.setFieldValue("ShadeComment", props.data.shade);
                                                        //     }
                                                        // }}
                                                        {...formik.getFieldProps("Shade")}
                                                    />
                                                    <span></span>
                                                </label> */}
                                                    <p className="mb-0 ml-3">Shade {formik.values.Shade ? renderShadeLabel(formik.values.ShadeComment) : ""}</p>
                                                   { formik.values.Shade &&
                                                        <span className="btn btn-icon btn-clear btn-sm" 
                                                            onClick={() => {
                                                                // if (formik.values?.Shade) {
                                                                //     formik.setFieldValue("Shade", "");
                                                                // } else {
                                                                //     formik.setFieldValue("Shade", props.data.shade);
                                                                // }
                                                                formik.setFieldValue("Shade", !formik.values?.Shade);
                                                            }}
                                                        ><FaEdit className="font-size-h5 text-primary"/></span>
                                                    }

                                                {/* Input on the right */}
                                                {!formik.values.Shade && (
                                                    <div className="col-md-5">
                                                        <Select
                                                        menuPortalTarget={document.body}
                                                        styles={customShadeStyles(selectedShadeOption)}
                                                        name="ShadeComment"
                                                        options={shadeOptions}
                                                        className={`${getInputClasses("ShadeComment")}`}
                                                        onChange={(selected) => {
                                                            formik.setFieldValue("ShadeComment", selected ? selected.value : null); // Ensure to handle the case when selected is null
                                                            formik.setFieldValue("Shade", true);
                                                        }}
                                                        value={shadeOptions.find(value => value.value === formik.values.ShadeComment)}
                                                        components={{ Option: CustomOption }}
                                                    />
                                                    {formik.touched.ShadeComment && formik.errors.ShadeComment ? (
                                                        <div className="invalid-feedback small">
                                                            {formik.errors.ShadeComment}
                                                        </div>
                                                    ) : null}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error message for the checkbox */}
                                            {formik.touched.Shade && formik.errors.Shade ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.Shade}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-1">
                                            <div className="row align-items-center">
                                                {/* Checkbox on the left */}
                                                {/* <label className="checkbox col-md-3 d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="pr-3"
                                                        name="Milky"
                                                        checked={formik.values?.Milky}
                                                        disabled= {!props?.data?.milky}
                                                        // onClick={() => {
                                                        //     if (formik.values?.Milky) {
                                                        //         formik.setFieldValue("MilkyComment", "");
                                                        //     } else {
                                                        //         formik.setFieldValue("MilkyComment", props.data.milky);
                                                        //     }
                                                        // }}
                                                        {...formik.getFieldProps("Milky")}
                                                    />
                                                    <span></span>
                                                    <p className="mb-0 ml-3">Milky {formik.values.Milky ? renderMilkyLabel(formik.values.MilkyComment) : ""}</p>
                                                </label> */}
                                                <p className="mb-0 ml-3">Milky {formik.values.Milky ? renderMilkyLabel(formik.values.MilkyComment) : ""}</p>
                                                   { formik.values.Milky &&
                                                        <span className="btn btn-icon btn-clear btn-sm" 
                                                            onClick={() => {
                                                                if (formik.values?.Shade) {
                                                                    formik.setFieldValue("Milky", "");
                                                                } else {
                                                                    formik.setFieldValue("Milky", props.data.milky);
                                                                }
                                                                formik.setFieldValue("Milky", !formik.values?.Milky);
                                                            }}
                                                        ><FaEdit className="font-size-h5 text-primary"/></span>
                                                    }

                                                {/* Input on the right */}
                                                {!formik.values.Milky && (
                                                    <div className="col-md-5">
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={customShadeStyles(selectedMilkyOption)}
                                                            name="MilkyComment"
                                                            options={milkyOptions}
                                                            className={`${getInputClasses("MilkyComment")}`}
                                                            onChange={(selected) => {
                                                                formik.setFieldValue("MilkyComment", selected ? selected.value : null); 
                                                                formik.setFieldValue("Milky", true);
                                                            }}
                                                            value={milkyOptions.find(value => value.value === formik.values.MilkyComment)}
                                                            components={{ Option: CustomOption }}
                                                        />
                                                        {formik.touched.MilkyComment && formik.errors.MilkyComment ? (
                                                            <div className="invalid-feedback small">
                                                                {formik.errors.MilkyComment}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error message for the checkbox */}
                                            {formik.touched.Milky && formik.errors.Milky ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.Milky}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-1">
                                            <div className="row align-items-center">
                                                {/* Checkbox on the left */}
                                                {/* <label className="checkbox col-md-3 d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="pr-3"
                                                        name="EyeClean"
                                                        checked={formik.values?.EyeClean}
                                                        disabled= {!props?.data?.eyeC}
                                                        // onClick={() => {
                                                        //     if (formik.values?.EyeClean) {
                                                        //         formik.setFieldValue("EyeCleanComment", "");
                                                        //     } else {
                                                        //         formik.setFieldValue("EyeCleanComment", props.data.eyeC);
                                                        //     }
                                                        // }}
                                                        {...formik.getFieldProps("EyeClean")}
                                                    />
                                                    <span></span>
                                                    <p className="mb-0 ml-3">EyeClean {formik.values.EyeClean ? renderEyecleanLabel(formik.values.EyeCleanComment) : ""}</p>
                                                </label> */}

                                                <p className="mb-0 ml-3">EyeClean {formik.values.EyeClean ? renderEyecleanLabel(formik.values.EyeCleanComment) : ""}</p>
                                                   { formik.values.EyeClean &&
                                                        <span className="btn btn-icon btn-clear btn-sm" 
                                                            onClick={() => {
                                                                if (formik.values?.Shade) {
                                                                    formik.setFieldValue("EyeClean", "");
                                                                } else {
                                                                    formik.setFieldValue("EyeClean", props.data.eyeC);
                                                                }
                                                                formik.setFieldValue("EyeClean", !formik.values?.EyeClean);
                                                            }}
                                                        ><FaEdit className="font-size-h5 text-primary"/></span>
                                                    }

                                                {/* Input on the right */}
                                                {!formik.values.EyeClean && (
                                                    <div className="col-md-5">
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={customShadeStyles(selectedEyeCleanOption)}
                                                            name="EyeCleanComment"
                                                            options={eyeCleanOptions}
                                                            className={`${getInputClasses("EyeCleanComment")}`}
                                                            onChange={(selected) => {
                                                                formik.setFieldValue("EyeCleanComment", selected ? selected.value : null); 
                                                                formik.setFieldValue("EyeClean", true);
                                                            }}
                                                            value={eyeCleanOptions.find(value => value.value === formik.values.EyeCleanComment)}
                                                            components={{ Option: CustomOption }}

                                                        />
                                                        {formik.touched.EyeCleanComment && formik.errors.EyeCleanComment ? (
                                                            <div className="invalid-feedback small">
                                                                {formik.errors.EyeCleanComment}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error message for the checkbox */}
                                            {formik.touched.EyeClean && formik.errors.EyeClean ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.EyeClean}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* {!formik.values?.EyeClean?<hr className="w-75 ml-0"/>:<hr className="w-25 ml-0"/>} */}
                                        {formik.values.DisplayLuster?<div className="form-group mb-1">
                                            <div className="row align-items-center">
                                                {/* Checkbox on the left */}
                                                {/* <label className="checkbox col-md-3 d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="pr-3"
                                                        name="Luster"
                                                        checked={formik.values?.Luster}
                                                        disabled= {!props?.data?.luster}
                                                        // onClick={() => {
                                                        //     if (formik.values?.Luster) {
                                                        //         formik.setFieldValue("LusterComment", "");
                                                        //     } else {
                                                        //         formik.setFieldValue("LusterComment", props.data.luster);
                                                        //     }
                                                        // }}
                                                        {...formik.getFieldProps("Luster")}
                                                    />
                                                    <span></span>
                                                    <p className="mb-0 ml-3">Luster {formik.values.Luster ? formik.values.LusterComment : ""}</p>
                                                </label> */}
                                                <p className="mb-0 ml-3">Luster {formik.values.Luster ? renderMilkyLabel(formik.values.LusterComment) : ""}</p>
                                                   { formik.values.Milky &&
                                                        <span className="btn btn-icon btn-clear btn-sm" 
                                                            onClick={() => {
                                                                if (formik.values?.Shade) {
                                                                    formik.setFieldValue("Luster", "");
                                                                } else {
                                                                    formik.setFieldValue("Luster", props.data.eyeC);
                                                                }
                                                                formik.setFieldValue("Luster", !formik.values?.Luster);
                                                            }}
                                                        ><FaEdit className="font-size-h5 text-primary"/></span>
                                                    }

                                                {/* Input on the right */}
                                                {!formik.values.Luster && (
                                                    <div className="col-md-5">
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={customShadeStyles(selectedLusterOption)}
                                                            name="LusterComment"
                                                            options={lusterOptions}
                                                            className={`${getInputClasses("LusterComment")}`}
                                                            onChange={(selected) => {
                                                                formik.setFieldValue("LusterComment", selected ? selected.value : null);
                                                                formik.setFieldValue("Luster", true);
                                                            }}
                                                            components={{ Option: CustomOption }}
                                                            value={lusterOptions.find(value => value.value === formik.values.LusterComment)}
                                                        />
                                                        {formik.touched.LusterComment && formik.errors.LusterComment ? (
                                                            <div className="invalid-feedback small">
                                                                {formik.errors.LusterComment}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error message for the checkbox */}
                                            {formik.touched.Luster && formik.errors.Luster ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.Luster}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>:""}
                                        {/* {formik.values.DisplayLuster && !formik.values?.Luster?<hr className="w-75 ml-0"/>:formik.values.DisplayLuster?<hr className="w-25 ml-0"/>:""} */}
                                        {formik.values.DisplayGrowthType?<div className="form-group mb-1">
                                            <div className="row align-items-center">
                                                {/* Checkbox on the left */}
                                                {/* <label className="checkbox col-md-3 d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="pr-3"
                                                        name="GrowthType"
                                                        checked={formik.values?.GrowthType}
                                                        disabled= {!props?.data?.growthType}
                                                        // onClick={() => {
                                                        //     if (formik.values?.GrowthType) {
                                                        //         formik.setFieldValue("GrowthTypeComment", "");
                                                        //     } else {
                                                        //         formik.setFieldValue("GrowthTypeComment", props.data.growthType);
                                                        //     }
                                                        // }}
                                                        {...formik.getFieldProps("GrowthType")}
                                                    />
                                                    <span></span>
                                                    <p className="mb-0 ml-3">GrowthType {formik.values.GrowthType ? renderGrowthTypeLabel(formik.values.GrowthTypeComment) : ""}</p>
                                                </label> */}
                                                <p className="mb-0 ml-3">EyeClean {formik.values.GrowthType ? renderGrowthTypeLabel(formik.values.GrowthTypeComment) : ""}</p>
                                                   { formik.values.Milky &&
                                                        <span className="btn btn-icon btn-clear btn-sm" 
                                                            onClick={() => {
                                                                if (formik.values?.Shade) {
                                                                    formik.setFieldValue("GrowthType", "");
                                                                } else {
                                                                    formik.setFieldValue("GrowthType", props.data.growthType);
                                                                }
                                                                formik.setFieldValue("GrowthType", !formik.values?.GrowthType);
                                                            }}
                                                        ><FaEdit className="font-size-h5 text-primary"/></span>
                                                    }

                                                {/* Input on the right */}
                                                {!formik.values.GrowthType && (
                                                    <div className="col-md-5">
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={customShadeStyles(selectedGrowthTypeOption)}
                                                            name="GrowthTypeComment"
                                                            options={growthTypeOptions}
                                                            className={`${getInputClasses("GrowthTypeComment")}`}
                                                            onChange={(selected) => {
                                                                formik.setFieldValue("GrowthTypeComment", selected ? selected.value : null);
                                                                formik.setFieldValue("GrowthType", true);
                                                            }}
                                                            components={{ Option: CustomOption }}
                                                            value={growthTypeOptions.find(value => value.value === formik.values.GrowthTypeComment)}
                                                        />
                                                        {formik.touched.GrowthTypeComment && formik.errors.GrowthTypeComment ? (
                                                            <div className="invalid-feedback small">
                                                                {formik.errors.GrowthTypeComment}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error message for the checkbox */}
                                            {formik.touched.GrowthType && formik.errors.GrowthType ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.GrowthType}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>:""}
                                        {/* {formik.values.DisplayGrowthType && !formik.values?.GrowthType?<hr className="w-75 ml-0"/>:formik.values.DisplayGrowthType?<hr className="w-25 ml-0"/>:""} */}
                                        <div className="form-group mb-1">
                                            <div className="row align-items-center">
                                                {/* Checkbox on the left */}
                                                {/* <label className="checkbox col-md-3 d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="pr-3"
                                                        name="Open"
                                                        checked={formik.values?.Open}
                                                        onClick={() => {
                                                            if (formik.values?.Open) {
                                                                formik.setFieldValue("OpenComment", "");
                                                            } else {
                                                                formik.setFieldValue("OpenComment", "No Open");
                                                            }
                                                        }}
                                                        {...formik.getFieldProps("Open")}
                                                    />
                                                    <span></span>
                                                    <p className="mb-0 ml-3">No Open</p>
                                                </label> */}
                                                
                                                <p className="mb-0 ml-3">Open : {formik.values.Open ? (formik.values.OpenComment) : ""} </p>
                                                { formik.values.Open && 
                                                
                                                    <span className="btn btn-icon btn-clear btn-sm" 
                                                        onClick={() => {
                                                            formik.setFieldValue("Open", !formik.values?.Open);
                                                        }}
                                                    ><FaEdit className="font-size-h5 text-primary"/></span>
                                                }

                                                {/* Input on the right */}
                                                {!formik.values.Open && (
                                                    <div className="col-md-4 d-flex">
                                                        <input
                                                            type="text"
                                                            placeholder="Please enter Open Comment"
                                                            className={`form-control form-control-sm ${getInputClasses("OpenComment")}`}
                                                            name="OpenComment"
                                                            {...formik.getFieldProps("OpenComment")}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("OpenComment", e.target.value);
                                                            }}
                                                        />
                                                        <span className="btn btn-icon btn-clear btn-sm" 
                                                        onClick={() => {
                                                            formik.setFieldValue("Open", true);
                                                        }}
                                                    ><FaSave className="font-size-h5 text-primary"/></span>
                                                        {formik.touched.OpenComment && formik.errors.OpenComment ? (
                                                            <div className="invalid-feedback small">
                                                                {formik.errors.OpenComment}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Error message for the checkbox */}
                                            {formik.touched.Open && formik.errors.Open ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.Open}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3 mb-1">
                                            <label htmlFor="comments">
                                                Additional Comments
                                            </label>
                                            <textarea
                                                className={`form-control ${getInputClasses("comments")}`}
                                                name="comments"
                                                id="comments"
                                                placeholder="comments"
                                                rows="3"
                                                {...formik.getFieldProps("comments")}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="py-3 justify-content-between">
                        <Button
                            variant="danger"
                            className="font-weight-bold"
                            onClick={() => closeModel(props)}
                        >
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                        >
                            <span>Approve hold request</span>
                            {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};
